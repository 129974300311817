.App {
    -webkit-animation: fade-in .7s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
    animation: fade-in .7s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

* {
    -webkit-tap-highlight-color: transparent;
}

@font-face {
    font-family: "Sanz-RegCond";
    src: local("Sanzettica-RegCond"), 
    url("../fonts/Sanzettica-RegCond.ttf") format("truetype");
   }

   @font-face {
    font-family: "Sanz-Reg";
    src: local("Sanzettica-Reg"), 
    url("../fonts/Sanzettica-Reg.ttf") format("truetype");
   }
  
   @font-face {
    font-family: "Sanz-ThinCond";
    src: local("Sanzettica-ThinCond"), 
    url("../fonts/Sanzettica-ThinCond.ttf") format("truetype");
   }

   @font-face {
    font-family: "Sanz-Thin";
    src: local("Sanzettica-Thin"), 
    url("../fonts/Sanzettica-Thin.ttf") format("truetype");
   }

   @font-face {
    font-family: "Sanz-ThinItalic";
    src: local("Sanzettica-ThinItalic"), 
    url("../fonts/Sanzettica-ThinItalic.ttf") format("truetype");
   }


   @font-face {
    font-family: "Sanz-RegItalic";
    src: local("Sanzettica-RegItalic"), 
    url("../fonts/Sanzettica-RegItalic.ttf") format("truetype");
   }
  
   @font-face {
    font-family: "Sanz-Heavy";
    src: local("Sanzettica-Heavy"), 
    url("../fonts/Sanzettica-Heavy.ttf") format("truetype");
   }
  
   @font-face {
    font-family: "Sanz-Black";
    src: local("Sanzettica-BlackCond"), 
    url("../fonts/Sanzettica-BlackCond.ttf") format("truetype");
   }
  
   @font-face {
    font-family: "Sanz-Thin-Italic";
    src: local("Sanzettica-ThinItalic"), 
    url("../fonts/Sanzettica-ThinItalic.ttf") format("truetype");
   }

   .scale-out-ver-top {
	-webkit-animation: scale-out-ver-top 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
	        animation: scale-out-ver-top 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
}

   .scale-up-ver-top {
	-webkit-animation: scale-up-ver-top 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	        animation: scale-up-ver-top 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}


@-webkit-keyframes scale-up-ver-top {
    0% {
      -webkit-transform: scaleY(0.4);
              transform: scaleY(0.4);
      -webkit-transform-origin: 100% 0%;
              transform-origin: 100% 0%;
    }
    100% {
      -webkit-transform: scaleY(1);
              transform: scaleY(1);
      -webkit-transform-origin: 100% 0%;
              transform-origin: 100% 0%;
    }
  }
  @keyframes scale-up-ver-top {
    0% {
      -webkit-transform: scaleY(0.4);
              transform: scaleY(0.4);
      -webkit-transform-origin: 100% 0%;
              transform-origin: 100% 0%;
    }
    100% {
      -webkit-transform: scaleY(1);
              transform: scaleY(1);
      -webkit-transform-origin: 100% 0%;
              transform-origin: 100% 0%;
    }
  }

  @-webkit-keyframes scale-out-ver-top {
    0% {
      -webkit-transform: scaleY(1);
              transform: scaleY(1);
      -webkit-transform-origin: 100% 0%;
              transform-origin: 100% 0%;
      opacity: 1;
    }
    100% {
      -webkit-transform: scaleY(0);
              transform: scaleY(0);
      -webkit-transform-origin: 100% 0%;
              transform-origin: 100% 0%;
      opacity: 1;
    }
  }
  @keyframes scale-out-ver-top {
    0% {
      -webkit-transform: scaleY(1);
              transform: scaleY(1);
      -webkit-transform-origin: 100% 0%;
              transform-origin: 100% 0%;
      opacity: 1;
    }
    100% {
      -webkit-transform: scaleY(0);
              transform: scaleY(0);
      -webkit-transform-origin: 100% 0%;
              transform-origin: 100% 0%;
      opacity: 1;
    }
  }
  
  

.front-page-vid-container {
    position: relative;
    height: 100%;
    max-height: 100vh;
    overflow: none;
  }

  video.fit-abs {

  }
  
  .front-page-vid-overlay {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(rgba(0, 0, 0, 0.68), transparent, rgba(0, 0, 0, 0.226), transparent);
    background: none;
  }

  * {
    color: black;
    color: rgba(0, 0, 0, 0.616);
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    margin: 0;
}

input.code {
    margin-top: 10px;
    margin-right: 10px;
    padding: 7px;
    font-size: 16px;
  }
  
  .question {
    font-style: italic;
    color: rgb(128, 0, 94);
    margin-top: 10px;
    padding: 7px;
    padding-left: 0;
    font-weight: 500;
  }

  button.enter {
  
        color: inherit;
        border: none;
      margin: 10px auto;
      background-color: black;
      color: white;
      padding: 10px;
    
        outline: inherit;
    
  }

.entry-text-low {
    position: absolute;
    font-size: 14px;
    line-height: 1.47059;
    font-weight: 300;
    letter-spacing: -0.5px;
    font-family: "SF Pro Text","SF Pro Icons","Helvetica Neue","Helvetica","Arial",sans-serif;
    width: 100%;
    text-align: left;
    top: 70%;
    color: white;
  
    font-size: 12px;
    
    line-height: 1.6;
    font-weight: 200;
    text-align: center;
    letter-spacing: 1.2px;

    width: 100%;
    color: white;
    z-index: 200;

  }

  .top70 {
      top: 70%;

  }
  .black-friday-special {
    font-weight: 900;
    color: white;
    z-index: 200;
    display: block;
    font-size: 28px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: lighter;
    margin: 0 auto;
    position: relative;
    text-align: center;
    width: 100%;
  
    top: 60%;
    line-height: 1.05em;
    padding-top: 5px;
    padding-bottom: 8px;
    -webkit-font-smoothing: antialiased;
    left: 50%; transform: translate(-50%);
    color: white;
    font-weight: 100;
    font-size: 22=4px;
    line-height: 1.47059;
    font-weight: lighter;
    letter-spacing: 0.5px;
    font-family: "SF Pro Text","SF Pro Icons","Helvetica Neue","Helvetica","Arial",sans-serif;
    width: 100%;
 }

 .pad-left-5-p {
     padding-left: 5%;
 }



 .uppercase {
     text-transform: uppercase;
 }

 .inline-block {
    width: 50%;
    display: inline-block;
 }

 .italic-font {
     font-style: italic;
     text-transform: capitalize;
 }


img.landing-page-image {
    position: fixed;
    max-height: 101vh;
    overflow: hidden;
    top: 0;
    left: 0;
    width: 100%;
    z-index: -10;
    opacity: .6;
    background-color: transparent;
}



.front-page-title {
    font-size: 8.8vh;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: lighter;
    margin: 0 auto;
    position: absolute;
    height: fit-content;
    top: 46%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    z-index: 90;
    width: 100%;
    color: white;
    background-color: transparent;
}

.front-page-logo {
    width: 100%;
}

.opacity-overlay {
    z-index: -300;
    background-color: rgb(0, 0, 0);
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 101vh;
}

.mobile-navbar {
    position: fixed;
    bottom: 0em;
    display: block;
    margin: 0 auto;
    margin-top: 2.5em;
    padding-top: 0em;
    padding-top: 0;
    padding-bottom: .6em;
    background: white;
    text-align: center;
    width: 100%;
    z-index: 60;
    border-top: none;
    background-color: white;
}

i {
 background-color: transparent;
 color: black;
 font-weight: inherit;
 font-style: inherit;
}

i.fa-star {
    color: white;
}

.mobile-navbar-link {
    display: inline-block;
    width: 16%;
    padding: 2%;
    padding-top: 1.2em;
    padding-bottom: 1em;

    font-size: 8pt;
    font-weight: 400;
    background-color: rgb(19, 19, 19);
    background-color: transparent;
    margin-left: .3em;
    margin-right: .3em;
    margin: 0 auto;
    border-radius: 7px;
    color: white;
    opacity: 1;
    text-decoration: none;
    vertical-align: top;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.mobile-navbar-link i {
    font-size: 20pt;
    padding: 4px;
    font-weight: 400;
}

.right-link i {
    color: transparent;
    font-size: 15pt;
}

.test-font {
    color: black;
}

.get-started {
    position: absolute;
    top: 50%;
    transform: translate(-50%);
    left: 50%;
    display: block;
    width: 160px;
    height: 52px;
    margin: 20px auto;
    vertical-align: middle;
    font-size: 40px;
    color: white;
    padding: 0px;
    text-align: center;
    text-transform: uppercase;
    font-size: 13px;
    font-weight: 500;
    font-size: 15px;
    -webkit-appearance: none;
    transition: .4s;
    border-image: linear-gradient(to right, #005e7f, #61b6cd);
    border-radius: 50px;
    background-size: 100%;
    background: white;
    border: 2px transparent;
    border-radius: 30px;
    background-origin: border-box;
    background-clip: content-box, border-box;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    z-index: 0;
  }
  
  .get-started::after {
    z-index: -100;
    border-radius: 50px;
    content: '';
    display: block;    
    position: absolute;
    top: -1px;
    left: -1px;
    width: calc(100% + 2.5px);
    height: calc(100% + 2px);
    background: linear-gradient(#61b6cd 35%, #005e7f 45%);
    background: linear-gradient(to right, #7b4397, #e72f60);
    background-size: 100% 200%;
    background-position: 0 var(--modal-button-gradient-pos);
    transform: translateZ(-1px);
  }

  .entry-btn {
    position: absolute;
    top: 46%;
    transform: translate(0, -50%);
    display: block;
    width: 100%;
    height: 52px;
    margin: 15px auto;
    vertical-align: middle;
    font-size: 40px;
    color: white;
    padding: 0px;
    text-align: center;
    text-transform: uppercase;

    font-size: 8.5pt;
    font-weight: 400;
    font-family: Helvetica_Regular,Arial,sans-serif;
    -webkit-appearance: none;
    transition: .4s;
    border-radius: 50px;
    background-size: 100%;
    background: transparent;
    border: 2px transparent;
    border-radius: 30px;
    background-origin: border-box;
    background-clip: content-box, border-box;
    z-index: 0;
    text-decoration: underline;
  }
  
  span.btn-text {
    position: absolute;
    width: 100%;
    left: 0;
    top: 51%;
    transform: translate(0, -50%);
    color: white;
  }
  
  a.get-started {
   font-weight: 600;
   font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
   
  }

  .menu {
        position: fixed;
        display: block;
        color: rgb(255, 255, 255);
        width: 70%;
        border: none;
        top: 0em;
        opacity: 1;
        background-color: white;
        background-color: transparent;
        box-shadow: none;
        text-align: left;
        right: 0;
        height: 100vh;
        z-index: 9999000;
        transition: .4s cubic-bezier(0.6, -0.28, 0.735, 0.045);
  }

  .page {
    position: relative;
    padding: 0px;
    padding-top: 47px;
    margin: 0;
    padding-left: 10px;
    padding-right: 10px;
    background-color: white;
    background-color: transparent;
}

.full-products {
    margin-top: 50px;
}

.full-background {
    background-color: white;
    background-color: transparent;
    z-index: -100;
    top: 0;
    left: 0;
    right: 0;
    margin: 0;
    min-height: 100vh;
}

.artist-page-title {
    font-size: 14pt;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: lighter;
    padding: .2em;
    padding-left: 0;
    border-bottom: 1px solid #3a3939;
    color: white;
    vertical-align: middle;
    width: 100%;
 }

 a.read-more-2 {
    font-weight: 400;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 9pt;
    color: #666;
     padding: 0;
    display: inline-block;
    z-index: 1;
 }
 
 div.read-more-2 {
     font-weight: 400;
     font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
     font-size: 11pt;
     color: #666;
      padding: 0;
      margin-top: -3.2em;
     text-align: right;
     z-index: 1;
  }

  .PMU-detail-page-dropped {
  
    color: white;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 11pt;
    font-weight: 300;
    line-height: 1.4em;
    max-width: 100%;
 }

 .PMU-detail-hidden {
     color: white;
     font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
     font-size: 11pt;
     line-height: 1.4em; 
     font-weight: 300;
     height: 2.8em;
     overflow: hidden;
     text-overflow: ellipsis;
     max-width: 100%;
 }

 a.read-less {
    width: 0;
    display: none;
    color: transparent;
 }

 .schedule-btn {
    position: relative;
    display: block;
    width: 88%;
    padding: 20px;
    margin: 0px auto;
    margin-top: 30px;
    vertical-align: middle;
    font-size: 40px;
    color: white;
    text-align: center;
    text-transform: uppercase;
    font-size: 13px;
    -webkit-appearance: none;
    transition: .4s;
      border-radius: 50px;
       border-radius: 30px;
      background-size: 100%;
      background: transparent;
      border: 2px transparent;
      background-origin: border-box;
      background-clip: content-box, border-box;
      z-index: 1;
      font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  }

.schedule-btn::after {
    z-index: -1;
    border-radius: 50px;
    content: '';
    display: block;    
    position: absolute;
    top: -1px;
    left: -1px;
    width: calc(100% + 2.5px);
    height: calc(100% + 2px);
    background: linear-gradient(to right, #7b4397, #e72f60);
    background-size: 100% 200%;
    background-position: 0 var(--modal-button-gradient-pos);
    transform: translateZ(-1px);
}

/* Schedule */
.schedule-open {
    position: fixed;
    top: 0;
    bottom: 0;
    background: linear-gradient(to bottom right, #7b4397, #e72f60);
    height: 100vh;
    width: 100%;
    text-align: center;
    z-index: 999999999999999999999999999999;
}

.schedule-inside {
    position: absolute;
    top: 70px;
    left: 50%;
    transform: translate(-50%);
    width: 90%;
    margin: 0 auto;
}

iframe.schedule {
    position: relative;
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: 0px;
    height: 75vh;
    background-color: transparent;
    scrollbar-width: 0px;
    scrollbar-color: transparent;
    -webkit-scrollbar-color: transparent;
}

.schedule-close {
    position: absolute;
    top: 20px;
    font-size: 11pt;
    padding: 5px;
    text-transform: uppercase;
    color: white;
    left: 20px;
    text-align: left;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: 500;
    width: 100%;
    background-color: transparent;
}

.schedule-close:hover {
    cursor: pointer;
}

.schedule-close i {
    font-size: 11pt;
    font-weight: inherit;
}

.ysl {
    font-family: Helvetica_Regular,Arial,sans-serif;
    font-weight: 550;
    text-transform: uppercase;
    font-size: 11pt;
    -webkit-font-smoothing: antialiased;
}

img.match-height {
    z-index: -1;
    opacity: 1;
    max-height: unset;
    position: absolute;
    max-height: 93vh;
    height: 100%;
    max-width: unset;
    width: auto;
    top: 54px;
    left: 50%;
    transform: translate(-50%);
    overflow: hidden;
    background-color: transparent;

}

.main-page {
    position: relative;
    width: 100%;
    overflow: hidden;
    height: 100vh;
    padding-bottom: 100px;
    background-color: transparent;

}

.blk {
    color: black;
}

.blk i {
    color: black;
}

img {
  max-width: 100%;
}

.width-100 {
    width: 100%;
    display: block;
    margin: 0 auto;
    text-align: center;
}

img.width-100 {
    width: 480px;
}

.width-90 {
    width: 97%;

}

.width-full {
    width: 100%;
    height: auto;
    opacity: 1;
    text-align: center;
    background-color: linear-gradient(black, white, black);
    background-color: white;
}

.abs {
    position: absolute;
    top: 50%; left: 50%;
    transform: translate(-50%, -50%);
}



.overlay {
    position: absolute;
    z-index: 999;
    background-color: linear-gradient(to right, #7b4397, #e72f60);
}
 


.product-container {
    display: inline-block;
    position: relative;
    vertical-align: top;
    width: auto;
    margin-top: 5%;
    height: auto;
}

/* CART */
.cart-container {
    position: fixed;
    display: block;
    top: 50px;
    height: 100vh;
    background: white;
    width: 40%;
    z-index: 9;
    right: 0;
    transition: .4s;
    overflow-y: scroll;
    z-index: 999;
    text-align: center;
}


.float-left {
    position: absolute;
    left: 15px;
}

.cart-container.menu-button {
    position: fixed;
}

.menu-button-cart {
    position: fixed;
    z-index: 9999999999999999999999999;
    display: block;
    left: 10px;
    background: transparent;
}

.cart-scroll {
    position: relative;
    min-height: 120vh;
    height: fit-content;
    overflow: hidden;
    overflow-y: scroll;
    padding-bottom: 20vh;
    padding-top: 0px;
    width: 96%;
    margin: 0 auto;
}


.cart-item {
    display: block;
    margin-top: 5px;
    width: 100%;
    background-color: rgb(248, 248, 248);
    text-align: center;
}

.cart-item-name {
    display: block;
    text-align: left;
    margin-left: 12%;
    font-weight: 500;
 
}
.cart-image {
    height: 100px;
    text-align: left;
    display: inline-block;
}

span.third {
    width: 30%;
    display: inline-block;
    text-align: center;
    margin: 0 auto;
    vertical-align: middle;
    font-size: 8pt;
    text-transform: uppercase;
    color: rgba(0, 0, 0, 0.616);
    padding-top: 10px;
    padding-bottom: 10px;
}
.left {
    text-align: left;
}

.right {
    text-align: right;
}

img.cart-image {
    text-align: left;
}

.product-image-box {
    display: block;
    height: 22vw;
    width: 22vw;
    margin: 0 auto;
    overflow: hidden;
}



img.product {
    height: 100%;
    width: auto;

    margin: 0 auto;
    text-align: center;
    position: relative;
    display: block;
}


span.quantity-btn {
    position: relative;
    width: 25%;
    padding-top: 8px;
    padding-bottom: 8px;
    display: inline-block;
    text-align: center;
    margin: 0 auto;
    border: 1px solid rgba(0, 0, 0, 0.137);
    background-color: white;
}

span.num {
    border: none;
    
}



button {
    display: relative;
}

.shop-main {
    position: fixed;
    top: 0; left: 0; right: 0; bottom: 0;
    height: 100vh;
    overflow-y: scroll;
   
}

.shop {
    position: relative;
    background-color: rgb(248, 248, 248);
    text-align: center;
    position: relative;
    display: block;
    color: black;
    padding-bottom: 100px;
    width: 100%;
}

.call-to-action-text {
    position: relative;
  
    padding: 10px;
    width: 50%;
    margin: 20px auto;
    font-weight: lighter;
    color: #7b4397;
    
}

.product-name {
    padding-top: 4px;
    padding-bottom: 80px;
    text-align: left;
    width: 96%;
    margin: 0 auto;
}

.text-left {
    text-align: left;
}

.upcase {
    text-transform: uppercase;
}

.let-spacing-5 {
    letter-spacing: -0.3px;
}

.font-size-85pt {
    font-size: 9.5pt;
}

.font-size-9pt {
    font-size: 9pt;
    font-family: "Sanz-Thin", sans-serif;
}

.font-100 {
    font-weight: 100;
}

.font-weight-200 {
    font-weight: 200;
}

.font-300 {
    font-weight: 300;
}
.font-400 {
    font-weight: 500;
}

.product-image-gallery {
    margin-top: 1000px;
    padding-top: 310px;
    width: 800px;
    margin: 0 auto;
    position: relative;
    display: block;
    background: linear-gradient(transparent,  black);
}

.category-image {
    position: fixed;
    overflow: hidden;
    top: 0;
    max-height: 600px;

}

.width-full {
    width: 100%;
    margin: 0 auto;
}

.bname {
    background-clip: text;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    background: white;
   font-family: "Sanz-Reg", sans-serif;
   margin-top: 5px;
}

.bname::after {
    background: linear-gradient(306deg, black, #7b4397, black, #e72f60);

}


.main-product-name {
    font-size: 30px;
    display: inline-block;
    position: relative;
    width: fit-content;
    font-family: "Sanz-Thin", sans-serif;
}

.black-back {
    width: 100%;
    background: black;
}

.gray-back {
    width: 100%;
    background: black;

}



    .category-image {
        position: fixed;
        overflow: hidden;
        top: 50px;
        max-height: 150px;
        width: 100%;
    }

    .product-container {
        display: inline-block;
        position: relative;
        width: 25%;
        vertical-align: top;
        margin: 0 auto;
        margin-top: 0;
        text-align: center;
    }

    .product-image-gallery {
        margin-top: 0px;
        padding-top: 10px;
        margin: 0 auto;
        width: 100%;
        text-align: center;
        position: relative;
        background: linear-gradient(transparent, black, black, black);
        background: rgb(248, 248, 248);
    
    }


    .three {
        width: 30%;
    }

    .two {
        width: 50%
    }
    
    .cart-title {
        z-index: 99999;
        font-weight: 500;
        font-size: 10pt;
        top: 0;
    }

    .category-label {
        background-color: rgb(250, 250, 250);
        font-size: 14px;
        padding: 15px;
        padding-top: 15px;
        text-transform: uppercase;
        color: rgba(0, 0, 0, 0.616);
        color: #7b4397;
        text-align: left;
        position: sticky;
        top: 50px;
        z-index: 1000;
        letter-spacing: .01em;
        font-family: "Sanz-HeavyCond", sans-serif;
      

    }


    .category-label-2 {
        background-color: rgb(250, 250, 250);
        font-size: 14px;
        padding: 15px;
        padding-top: 15px;
        text-transform: uppercase;
        color: rgba(0, 0, 0, 0.616);
        color: #7b4397;
        text-align: left;
        position: sticky;
        top: 0px;
        z-index: 1000;
        letter-spacing: .01em;
        font-family: "Sanz-HeavyCond", sans-serif;
    }

    .category-label-3 {
        background-color: rgb(250, 250, 250);
        font-size: 11px;
        padding: 15px;
        padding-top: 15px;
        text-transform: uppercase;
        color: rgba(0, 0, 0, 0.616);
        color: #7b4397;
        text-align: left;
        position: sticky;
        top: 50px;
        z-index: 1000;
        letter-spacing: .01em;
        font-family: "Sanz-HeavyCond", sans-serif;
    }

    .category-label-3 a {
        font-synthesis: none;
        -moz-font-feature-settings: "kern";
        -webkit-font-smoothing: antialiased;
        color: transparent;
        background: linear-gradient(to right, #eb4191, #7c4a8a);
        background-clip: text;
        -webkit-background-clip: text;
        padding-bottom: 2px;
    }


    /* Hide scrollbar for Chrome, Safari and Opera */
*::-webkit-scrollbar {
    display: none;
  }
  
  /* Hide scrollbar for IE, Edge and Firefox */
  * {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }

    .selected-product {
        padding-top: 10px;
    }



    .category-label-cart {
        background-color: rgb(250, 250, 250);
        font-size: 7pt;
        padding: 10px;
        padding-top: 15px;
        text-transform: uppercase;
        color: rgba(0, 0, 0, 0.616);
        text-align: left;
        position: relative;
        z-index: 10;

    }

    .add-to-cart {
        position: relative;
        margin: 0 auto;
        width: 100%;
        bottom: 0;
        padding-top: 0px;
        padding-bottom: 0px;
        height: 50px;
        border: none;
        border-radius: 50px;
        color: white;
        transition: .7s;
        background: linear-gradient(to right, #7b4397, #e72f60);
        background: transparent;
        box-shadow: rgba(94, 87, 95, 0.2) 0px 7px 29px 0px;
        box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px;
    }

    .add-to-cart::after {
        z-index: -100000;
        border-radius: 50px;
        content: '';
        display: block;    
        position: absolute;
        top: -1px;
        left: -1px;
        width: calc(100% + 2.5px);
        height: calc(100% + 2px);
        transform: translateZ(-1px);
        background: linear-gradient(306deg, black, #7b4397, black, #e72f60);
        background-size: 400% 400%;
     
        -webkit-animation: WelcomePageGradient 4s ease infinite;
        -moz-animation: WelcomePageGradient 4s ease infinite;
        animation: WelcomePageGradient 4s ease infinite;
     }

     .section {
        margin-top: 10px;
        padding-top: 80px;
        height: 85vh;
        display: block;
        position: relative;
        background: transparent;
        text-align: center;
        overflow: hidden;
    }

     img.product-section-image {
        margin: 0 auto;
        position: absolute;
        bottom: -4%;
        left: 50%;
        transform: translate(-50%);
        z-index: 99;
        width: 680px;
     }

     .section-call-to-action {
        width: 80%;
        margin: 0 auto;
        text-align: center;
        margin-bottom: 30px;
        position: absolute;
        top: 44%; left: 50%; transform: translate(-50%, -50%);
        z-index: 999;
        padding: 20px;

     }

     .call-to-action, .call-to-action i {
        color: #b8afba;
        color: rgb(191, 90, 242);
        text-transform: uppercase;
        font-size: 18px;

        margin: 0 auto;
        z-index: 99999;
        font-synthesis: none;
        -moz-font-feature-settings: "kern";
        -webkit-font-smoothing: antialiased;
        color: transparent;
        background: linear-gradient(to bottom right, #eb4191, #a563b7);
        background-clip: text;
        -webkit-background-clip: text;
 
     }

     button.add-to-cart-small {
        position: absolute;
        top: 22vw;
        text-align: center;
        vertical-align: middle;
        right: 0px;
        width: 40px;
        height: 30px;
        background: transparent;
        z-index: 99999;
     }
     

     button.add-to-cart-small::after {
        z-index: -100000;
        border-radius: 50px;
        content: '';
        display: block;    
        position: absolute;
        top: -1px;
        left: -1px;
        width: calc(100% + 2.5px);
        height: calc(100% + 2px);
        transform: translateZ(-1px);
        background: linear-gradient(306deg, black, #7b4397, black,  #e72f60);
        background-size: 400% 400%;
     
        -webkit-animation: WelcomePageGradient 4s ease infinite;
        -moz-animation: WelcomePageGradient 4s ease infinite;
        animation: WelcomePageGradient 4s ease infinite;
     }

     .add-text {
         color: white;
         position: absolute;
         top: 50%; left: 50%;
         transform: translate(-50%, -50%);
         font-size: 9.5pt;
         font-weight: 300;
     }

    .add-to-cart-container {
        position: fixed;
        bottom: 10px;
        width: 40%;
        left: 50%; transform: translate(-50%);
        background-color: transparent;
        z-index: 999;
    }

    .add-to-cart-container5 {
        position: fixed;
        bottom: 10px;
        width: 30%;
        right: 5%; transform: translate(0%);
        background-color: transparent;
        z-index: 999999999999999;
    }




    button {
        border: 1px solid transparent;
        outline: none;
        box-shadow: none;
        width: 90%;
        height: 40px;
        background: linear-gradient(to right, #7b4397, #e72f60);
        background-size: 100% 200%;
        background: transparent;
        background-position: 0 var(--modal-button-gradient-pos);
        color: black;
        margin: 20px;
        font-weight: 300;
        font-size: 8.5pt;
    }

 

    .bname {
        background: linear-gradient(306deg, black, #7b4397, black, #e72f60);
        background-size: 300% 300%;
     
        -webkit-animation: WelcomePageGradient 6s ease infinite;
        -moz-animation: WelcomePageGradient 6s ease infinite;
        animation: WelcomePageGradient 6s ease infinite;
        background-clip: text;
        -webkit-background-clip: text;
        color: transparent;
     
        font-size: 12px;
        font-weight: 500;
    }

    .brand-name {
        font-size: 20px;
        font-weight: 300;
        text-transform: uppercase;
 
        font-family: "Sanz-ThinCond", sans-serif;
    }

    .cost {
        font-size: 18px;
        font-weight: 400;
        margin-top: 10px;
        display: inline-block;
        font-family: "Sanz-Thin", sans-serif;
    }

    .product-desc {
        margin-top: 10px;

        font-family: "Sanz-RegCond", sans-serif;
    }



.mobile-navbar-top {
    height: 50px;
    position: relative;
    background-color: white;
    display: block;
}

.view-by {
    text-transform: uppercase;
    letter-spacing: -0.5px;
    font-size: 9pt;
    text-align: left;
    padding-left: 25px;
    display: inline-block;
    font-weight: 300;
    width: 90%;
}

.selected {
    font-size: 9.5;
}

.space-10 {
    padding-left: 12px;
    padding-bottom: 20px;
    display: none;

}

.product-category {
    font-size: 11pt;
    
    padding: 20px;
}


.width-full {
    width: 100%;
    margin: 0 auto;
}



.c {
    text-align: center;
}


.no-pad {
    padding-bottom: 30px;
}

.trans-back {
    background-color: transparent;
}

.link {
    color: #7b4397;
    font-weight: 400;
}

.pad-20 {
    padding-bottom: 40px;
}

.pad-5 {
    padding-top: 5px;
}

.border-top {
    border-top: 1px solid rgba(0, 0, 0, 0.247);
}

.border-bottom {
    border-bottom: 1px solid rgba(0, 0, 0, 0.247);
}

.light-box-shadow {
    box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px;
}

.pad-top-btm {
    padding-top: 8px;
    padding-bottom: 8px;

}

button.category-label {
    padding-left: 0;
    padding-right: 0;
    width: 90%;

}

.wht {
    color: rgba(255, 255, 255, 0.911);
}

.cost {
    color: rgba(22, 22, 22, 0.911);
}

.name {
    color: rgb(22, 22, 22);
    padding-top: 15px;
}

* {
    -webkit-tap-highlight-color: transparent;
}

.black-back {
    width: 100%;
    background: black;
}

.sanz-black {
    font-family: "Sanz-Black", sans-serif;
}

.sanz-heavy {
    font-family: "Sanz-Heavy", sans-serif;
}

.sanz-reg {
    font-family: "Sanz-Reg", sans-serif;
}

.sanz-thin {
    font-family: "Sanz-Thin", sans-serif;
}

.apple-h2 {
    color: #cecece;
    color: white;
    display: inline-block;
    max-width: unset;
    margin: 0 auto;
    position: relative;
    z-index: 3;
    pointer-events: none;
    font-size: 92px;
    line-height: .9;
    font-weight: 600;
    letter-spacing: -.03em;
    font-synthesis: none;
    -moz-font-feature-settings: "kern";
    -webkit-font-smoothing: antialiased;
    color: transparent;
    background: linear-gradient(to right, #eb4191, #7c4a8a);
    background-clip: text;
    -webkit-background-clip: text;
    width: fit-content;
    background-size: cover;
    text-transform: uppercase;
    background-size: auto, auto;
}

.apple-h3 {
    color: #cecece;
    color: white;
    max-width: 57.6470588235rem;
    margin: 0 auto;
    position: relative;
    z-index: 3;
    pointer-events: none;
    font-size: 85px;
    line-height: .51;
    font-weight: 600;
    letter-spacing: -.03em;
    font-synthesis: none;
    text-transform: uppercase;
    -moz-font-feature-settings: "kern";
    -webkit-font-smoothing: antialiased;
}



.gray-font {
    color: #cecece;
}

.purple-back {
    background: transparent;
    position: absolute;
    top: 56%;
    width: 50%;
    left: 50%; transform: translate(-50%);
    display: block;
    z-index: -10;
    box-shadow: 0px 0px 180px 30px #e43bfa;
    opacity: .44;
}

.pink-back {
    background: transparent;
    position: absolute;
    top: 62%;
    width: 40%;
    left: 50%; transform: translate(-50%);
    display: block;
    z-index: -10;
    box-shadow: 0px 0px 180px 30px #fa3bd1;
    opacity: .44;
}

.purple-back-frontpg {
    background: transparent;
    position: absolute;
    top: 64%;
    width: 50%;
    left: 50%; transform: translate(-50%);
    display: block;
    z-index: 0;
    box-shadow: 0px 0px 180px 30px #e43bfa;
    opacity: .44;
}

.pink-back-frontpg {
    background: transparent;
    position: absolute;
    top: 73%;
    width: 40%;
    left: 50%; transform: translate(-50%);
    display: block;
    z-index: 0;
    box-shadow: 0px 0px 180px 30px #fa3bd1;
    opacity: .44;
}

.inkbrush-image {
   position: absolute;
   margin-top: -15%;
   width: 680px;
   left: 50%; transform: translate(-50%);
    
}

.fade-in-and-out {
    -webkit-animation: fade-in-and-out 4s ease-in-out infinite;
    -moz-animation: fade-in-and-out 4s  ease-in-out infinite;
    animation: fade-in-and-out 4s  ease-in-out infinite;
}

.fade-in-and-out2 {
    -webkit-animation: fade-in-and-out2 4s  ease-in-out infinite;
    -moz-animation: fade-in-and-out2 4s  ease-in-out infinite;
    animation: fade-in-and-out2 4s  ease-in-out infinite;
}

.height-60 {
    height: 60vh;

}

.apple-gray-back {
    background: #161617;
    width: 100%;
    padding: 20px;
    padding-top: 40px;
    padding-bottom: 130px;
    text-align: center;
    font-size: 28px;
    line-height: 1.6em;
    font-family: "Sanz-Thin", sans-serif;
}

.ink-gradient {
    color: transparent;
    background: linear-gradient(to right, #eb4191, #7c4a8a);
    background-clip: text;
    -webkit-background-clip: text;
    font-size: 29px;
    line-height: 1.6em;
    font-family: "Sanz-Thin", sans-serif;
}


.deeper-gray-font {
   color: #b4aab2;
   font-family: "Sanz-HeavyCond", sans-serif;
   line-height: 1.4285914286;
   letter-spacing: -0.016em;
   font-size: 16px;
   font-weight: 400;
}

.sanz-thin {
    font-family: "Sanz-Thin", sans-serif;
}

.inkbrush-text {
    position: absolute;
    left: 50%; transform: translate(-50%);
    width: 100%;
    padding-bottom: 0;
    margin-bottom: 0;
}

.sanz-regitalic {
    font-family: "Sanz-RegItalic", sans-serif;
}

a.purp-btn, a.purp-btn i {
    background: none;
    color: #7b4397;
    margin: 0 auto;
    text-transform: none;
    font-size: 16px;
    width: fit-content;
    font-weight: 400;
    border-radius: 0;
    margin-bottom: 20px;
}

button.purp-btn i { 
    font-weight: 400;
}

.no-caps {
    text-transform: none;
}

.abs-right {
    float: right;
    font-size: 16px;
}

.border-top-bottom {
    border-bottom: .5px solid #3a3939;


}

.discover-links {
    position: relative;
    top: 10px;
    text-align: center;
    width: 100%;
    font-family: "Sanz-Reg", sans-serif;
    font-size: 14px;
    line-height: 1.6em;
    margin: 0 auto;
    border-bottom: .5px solid #3a3939;
    padding-bottom: 10px;

}

.discover-link {
    padding: 7px;

}

.opacity-7 {
    opacity: .9;
    filter: grayscale(60%) blur(3px);
    
}

.black-bubble {
    margin-top: 4%;
    width: 500px;
    height: 500px;
    padding: 40px;
    left: 50%;
    transform: translate(-50%);
    background: black;
    border-radius: 20px;
    position: relative;
    display: block;
    background-image: url(https://cosmetinkshop.s3.us-east-2.amazonaws.com/inbrush_work2.PNG);
    background-size: 100% auto;
    background-repeat: no-repeat;
    background-position: left top;
}

.bubble-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(230deg, transparent, transparent, rgba(0, 0, 0, 0.226), #7b43976b);
    z-index: 9;
    border-radius: 20px;
}

.font-14 {
    font-size: 18px;
    line-height: 1.4em;
}

.bottom {
    width: 80%;
    position: absolute;
    bottom: 14%;
    left: 5%;
    text-align: left;
    z-index: 99;
    font-size: 20px;
    font-family: "Sanz-Heavy", sans-serif;
}

.black-bubble img.bubble-image {
   position: relative;
   display: block;
   width: 100%;
}

.white-back {
    background: white;
}

.black-back {background: black}

.line3 {
    padding: 0px;
    position: absolute;
    top: 33%;
    color: #cecece;
    z-index: 99;
    font-size: 24px;
}

.yellow-back-frontpg {
    background: transparent;
    position: absolute;
    top: 68%;
    width: 50%;
    left: 50%; transform: translate(-50%);
    display: block;
    z-index: 0;
    box-shadow: 0px 0px 180px 30px #6a2f8cd1;
    opacity: .44;
}

.white-back-frontpg {
    background: transparent;
    position: absolute;
    top: 72%;
    width: 40%;
    left: 50%; transform: translate(-50%);
    display: block;
    z-index: 0;
    box-shadow: 0px 0px 170px 20px #d7d7d7b3;
    opacity: .34;
}

.gray-back {
    width: 100%;
    background: #f5f5f7;
    color: black;

}

.blk {
    color: black;
}

.name2 {
    font-family: "Sanz-Reg", sans-serif;
    margin-top: 9px;
}
.name2 span {
    font-family: "Sanz-Thin", sans-serif;
}
.cost2 {
    font-family: "Sanz-Reg", sans-serif;
}

.success-page {
    position: fixed;
    z-index: 9999999999999999999999999999;
    height: 100vh;
    width: 100%;
}

.abs-c {
  position: absolute;
  top: 40%; left: 50%;
  transform: translate(-50%, -50%);
}
.add-to-cart-container2 {
    position: fixed;
    bottom: 10px;
    width: 80%;
    left: 50%; transform: translate(-50%);
    background-color: transparent;
    z-index: 99999999999999999999999999999999999999999999999;
}

.shipping-container {
    position: fixed;
    top: 50px;
    width: 40vw;
    
    height: 100vh;
    background: white;
   
    right: 0;
    overflow-y: scroll;
    z-index: 999999999999999999999999;
    transition: .7s;
}

.inside-shipping {
    padding: 20px;
    min-height: 140vh;
}

select {
    font-size: 16px;
    padding: 12px;
    margin-top: 20px;
    background: none;
    padding: 12px;
    width: 100%;
    border-radius: 5px;
    border-width: 1px;
    margin-top: 20px;
    font-size: 16px;
    border: 1px solid rgba(0, 0, 0, 0.15);
}

select#size {
    font-size: 16px;
    padding: 12px;
    margin-top: 0px;
    background: none;
    padding: 13px;
    width: 300px;
    color: white;
    border-radius: 5px;
    border-width: 1px;
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 16px;

    border: 1px solid white;
}

label {
    font-size: 16px;
}

.checkout {
    z-index: 9999999999999999999999999999999999999999999;
    transition: 4s;
}

.saving {
    padding-bottom: 20px;
    font-weight: 500;
    color: #7b4397;
}

.saving-no {
    padding-bottom: 10px;
    font-weight: 500;
    color: red;
}

@media (max-width: 800px) {
    select#size {
        font-size: 16px;
        padding: 12px;
        margin-top: 0px;
        background: none;
        padding: 13px;
        width: 100%;
        color: white;
        border-radius: 5px;
        border-width: 1px;
        margin-top: 10px;
        margin-bottom: 10px;
        font-size: 16px;
    
        border: 1px solid white;
    }

.front-page-vid-container {
    position: relative;
    height: 100%;
    max-height: 100vh;
    overflow: none;
  }

  video.fit-abs {

  }
  
  .front-page-vid-overlay {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(rgba(0, 0, 0, 0.68), transparent, rgba(0, 0, 0, 0.226), transparent);
    background: none;
  }

  * {
    color: black;
    color: rgba(0, 0, 0, 0.616);
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    margin: 0;
}


.entry-text-low {
    position: absolute;
    font-size: 14px;
    line-height: 1.47059;
    font-weight: 300;
    letter-spacing: -0.5px;
    font-family: "SF Pro Text","SF Pro Icons","Helvetica Neue","Helvetica","Arial",sans-serif;
    width: 100%;
    text-align: left;
    top: 70%;
    color: white;
  
    font-size: 12px;
    
    line-height: 1.6;
    font-weight: 200;
    text-align: center;
    letter-spacing: 1.2px;

    width: 100%;
    color: white;
    z-index: 200;

  }

  .top70 {
      top: 70%;

  }
  .black-friday-special {
    font-weight: 900;
    color: white;
    z-index: 200;
    display: block;
    font-size: 28px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: lighter;
    margin: 0 auto;
    position: relative;
    text-align: center;
    width: 100%;
  
    top: 60%;
    line-height: 1.05em;
    padding-top: 5px;
    padding-bottom: 8px;
    -webkit-font-smoothing: antialiased;
    left: 50%; transform: translate(-50%);
    color: white;
    font-weight: 100;
    font-size: 22=4px;
    line-height: 1.47059;
    font-weight: lighter;
    letter-spacing: 0.5px;
    font-family: "SF Pro Text","SF Pro Icons","Helvetica Neue","Helvetica","Arial",sans-serif;
    width: 100%;
 }

 .pad-left-5-p {
     padding-left: 5%;
 }



 .uppercase {
     text-transform: uppercase;
 }

 .inline-block {
    width: 100%;
    display: inline-block;
 }

 .italic-font {
     font-style: italic;
     text-transform: capitalize;
 }


img.landing-page-image {
    position: fixed;
    max-height: 101vh;
    overflow: hidden;
    top: 0;
    left: 0;
    width: 100%;
    z-index: -10;
    opacity: .6;
    background-color: transparent;
}



.front-page-title {
    font-size: 8.8vh;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: lighter;
    margin: 0 auto;
    position: absolute;
    height: fit-content;
    top: 46%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    z-index: 90;
    width: 100%;
    color: white;
    background-color: transparent;
}

.front-page-logo {
    width: 100%;
}

.opacity-overlay {
    z-index: -300;
    background-color: rgb(0, 0, 0);
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 101vh;
}

.mobile-navbar {
    position: fixed;
    bottom: 0em;
    display: block;
    margin: 0 auto;
    margin-top: 2.5em;
    padding-top: 0em;
    padding-top: 0;
    padding-bottom: .6em;
    background: white;
    text-align: center;
    width: 100%;
    z-index: 60;
    border-top: none;
    background-color: white;
}

i {
 background-color: transparent;
 color: black;
 font-weight: inherit;
 font-style: inherit;
}

i.fa-star {
    color: white;
}

.mobile-navbar-link {
    display: inline-block;
    width: 16%;
    padding: 2%;
    padding-top: 1.2em;
    padding-bottom: 1em;

    font-size: 8pt;
    font-weight: 400;
    background-color: rgb(19, 19, 19);
    background-color: transparent;
    margin-left: .3em;
    margin-right: .3em;
    margin: 0 auto;
    border-radius: 7px;
    color: white;
    opacity: 1;
    text-decoration: none;
    vertical-align: top;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.mobile-navbar-link i {
    font-size: 20pt;
    padding: 4px;
    font-weight: 400;
}

.right-link i {
    color: transparent;
    font-size: 15pt;
}

.test-font {
    color: black;
}

.get-started {
    position: absolute;
    top: 50%;
    transform: translate(-50%);
    left: 50%;
    display: block;
    width: 160px;
    height: 52px;
    margin: 20px auto;
    vertical-align: middle;
    font-size: 40px;
    color: white;
    padding: 0px;
    text-align: center;
    text-transform: uppercase;
    font-size: 13px;
    font-weight: 500;
    font-size: 15px;
    -webkit-appearance: none;
    transition: .4s;
    border-image: linear-gradient(to right, #005e7f, #61b6cd);
    border-radius: 50px;
    background-size: 100%;
    background: white;
    border: 2px transparent;
    border-radius: 30px;
    background-origin: border-box;
    background-clip: content-box, border-box;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    z-index: 0;
  }
  
  .get-started::after {
    z-index: -100;
    border-radius: 50px;
    content: '';
    display: block;    
    position: absolute;
    top: -1px;
    left: -1px;
    width: calc(100% + 2.5px);
    height: calc(100% + 2px);
    background: linear-gradient(#61b6cd 35%, #005e7f 45%);
    background: linear-gradient(to right, #7b4397, #e72f60);
    background-size: 100% 200%;
    background-position: 0 var(--modal-button-gradient-pos);
    transform: translateZ(-1px);
  }

  .entry-btn {
    position: absolute;
    top: 46%;
    transform: translate(0, -50%);
    display: block;
    width: 100%;
    height: 52px;
    margin: 15px auto;
    vertical-align: middle;
    font-size: 40px;
    color: white;
    padding: 0px;
    text-align: center;
    text-transform: uppercase;

    font-size: 8.5pt;
    font-weight: 400;
    font-family: Helvetica_Regular,Arial,sans-serif;
    -webkit-appearance: none;
    transition: .4s;
    border-radius: 50px;
    background-size: 100%;
    background: transparent;
    border: 2px transparent;
    border-radius: 30px;
    background-origin: border-box;
    background-clip: content-box, border-box;
    z-index: 0;
    text-decoration: underline;
  }
  
  span.btn-text {
    position: absolute;
    width: 100%;
    left: 0;
    top: 51%;
    transform: translate(0, -50%);
    color: white;
  }
  
  a.get-started {
   font-weight: 600;
   font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
   
  }

  .menu {
        position: fixed;
        display: block;
        color: rgb(255, 255, 255);
        width: 70%;
        border: none;
        top: 0em;
        opacity: 1;
        background-color: white;
        background-color: transparent;
        box-shadow: none;
        text-align: left;
        right: 0;
        height: 100vh;
        z-index: 9999000;
        transition: .4s cubic-bezier(0.6, -0.28, 0.735, 0.045);
  }

  .page {
    position: relative;
    padding: 0px;
    padding-top: 47px;
    margin: 0;
    padding-left: 10px;
    padding-right: 10px;
    background-color: white;
    background-color: transparent;
}

.full-products {
    margin-top: 50px;
}

.full-background {
    background-color: white;
    background-color: transparent;
    z-index: -100;
    top: 0;
    left: 0;
    right: 0;
    margin: 0;
    min-height: 100vh;
}

.artist-page-title {
    font-size: 14pt;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: lighter;
    padding: .2em;
    padding-left: 0;
    border-bottom: 1px solid #3a3939;
    color: white;
    vertical-align: middle;
    width: 100%;
 }

 a.read-more-2 {
    font-weight: 400;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 9pt;
    color: #666;
     padding: 0;
    display: inline-block;
    z-index: 1;
 }
 
 div.read-more-2 {
     font-weight: 400;
     font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
     font-size: 11pt;
     color: #666;
      padding: 0;
      margin-top: -3.2em;
     text-align: right;
     z-index: 1;
  }

  .PMU-detail-page-dropped {
  
    color: white;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 11pt;
    font-weight: 300;
    line-height: 1.4em;
    max-width: 100%;
 }

 .PMU-detail-hidden {
     color: white;
     font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
     font-size: 11pt;
     line-height: 1.4em; 
     font-weight: 300;
     height: 2.8em;
     overflow: hidden;
     text-overflow: ellipsis;
     max-width: 100%;
 }

 a.read-less {
    width: 0;
    display: none;
    color: transparent;
 }

 .schedule-btn {
    position: relative;
    display: block;
    width: 88%;
    padding: 20px;
    margin: 0px auto;
    margin-top: 30px;
    vertical-align: middle;
    font-size: 40px;
    color: white;
    text-align: center;
    text-transform: uppercase;
    font-size: 13px;
    -webkit-appearance: none;
    transition: .4s;
      border-radius: 50px;
       border-radius: 30px;
      background-size: 100%;
      background: transparent;
      border: 2px transparent;
      background-origin: border-box;
      background-clip: content-box, border-box;
      z-index: 1;
      font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  }

.schedule-btn::after {
    z-index: -1;
    border-radius: 50px;
    content: '';
    display: block;    
    position: absolute;
    top: -1px;
    left: -1px;
    width: calc(100% + 2.5px);
    height: calc(100% + 2px);
    background: linear-gradient(to right, #7b4397, #e72f60);
    background-size: 100% 200%;
    background-position: 0 var(--modal-button-gradient-pos);
    transform: translateZ(-1px);
}

/* Schedule */
.schedule-open {
    position: fixed;
    top: 0;
    bottom: 0;
    background: linear-gradient(to bottom right, #7b4397, #e72f60);
    height: 100vh;
    width: 100%;
    text-align: center;
    z-index: 999999999999999999999999999999;
}

.schedule-inside {
    position: absolute;
    top: 70px;
    left: 50%;
    transform: translate(-50%);
    width: 90%;
    margin: 0 auto;
}

iframe.schedule {
    position: relative;
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: 0px;
    height: 75vh;
    background-color: transparent;
    scrollbar-width: 0px;
    scrollbar-color: transparent;
    -webkit-scrollbar-color: transparent;
}

.schedule-close {
    position: absolute;
    top: 20px;
    font-size: 11pt;
    padding: 5px;
    text-transform: uppercase;
    color: white;
    left: 20px;
    text-align: left;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: 500;
    width: 100%;
    background-color: transparent;
}

.schedule-close:hover {
    cursor: pointer;
}

.schedule-close i {
    font-size: 11pt;
    font-weight: inherit;
}

.ysl {
    font-family: Helvetica_Regular,Arial,sans-serif;
    font-weight: 550;
    text-transform: uppercase;
    font-size: 11pt;
    -webkit-font-smoothing: antialiased;
}

img.match-height {
    z-index: -1;
    opacity: 1;
    max-height: unset;
    position: absolute;
    max-height: 93vh;
    height: 100%;
    max-width: unset;
    width: auto;
    top: 54px;
    left: 50%;
    transform: translate(-50%);
    overflow: hidden;
    background-color: transparent;

}

.main-page {
    position: relative;
    width: 100%;
    overflow: hidden;
    height: 100vh;
    padding-bottom: 100px;
    background-color: transparent;

}

.blk {
    color: black;
}

.blk i {
    color: black;
}

img {
  max-width: 100%;
}
.width-100 {
    width: 100%;
    display: block;
    margin: 0 auto;
    text-align: center;
}

img.width-100 {
    width: 100%;
}

.width-90 {
    width: 97%;

}

.width-full {
    width: 100%;
    height: auto;
    opacity: 1;
    text-align: center;
    background-color: linear-gradient(black, white, black);
    background-color: white;
}

.abs {
    position: absolute;
    top: 50%; left: 50%;
    transform: translate(-50%, -50%);
}



.overlay {
    position: absolute;
    z-index: 999;
    background-color: linear-gradient(to right, #7b4397, #e72f60);
}
 


.product-container {
    display: inline-block;
    position: relative;
    vertical-align: top;
    width: 25%;
    margin-top: 5%;
    height: 300px;
}

/* CART */
.cart-container {
    position: fixed;
    display: block;
    top: 50px;
    height: 100vh;
    right: unset;
    background: white;
    width: 100%;
    z-index: 9;
    transition: .4s;
    overflow-y: scroll;
    z-index: 999;
    text-align: center;
}


.float-left {
    position: absolute;
    left: 15px;
}

.cart-container.menu-button {
    position: fixed;
}

.menu-button-cart {
    position: fixed;
    z-index: 9999999999999999999999999;
    display: block;
    left: 10px;
    background: transparent;
}

.cart-scroll {
    position: relative;
    min-height: 120vh;
    height: fit-content;
    overflow: hidden;
    overflow-y: scroll;
    padding-bottom: 30vh;
    padding-top: 0px;
    width: 96%;
    margin: 0 auto;
}


.cart-item {
    display: block;
    margin-top: 5px;
    width: 100%;
    background-color: rgb(248, 248, 248);
    text-align: center;
}

.cart-item-name {
    display: block;
    text-align: left;
    margin-left: 12%;
    font-weight: 500;
 
}
.cart-image {
    height: 100px;
    text-align: left;
    display: inline-block;
}

span.third {
    width: 30%;
    display: inline-block;
    text-align: center;
    margin: 0 auto;
    vertical-align: middle;
    font-size: 8pt;
    text-transform: uppercase;
    color: rgba(0, 0, 0, 0.616);
    padding-top: 10px;
    padding-bottom: 10px;
}
.left {
    text-align: left;
}

.right {
    text-align: right;
}

img.cart-image {
    text-align: left;
}

.product-image-box {
    display: block;
    height: 48vw;
    width: 48vw;
    margin: 0 auto;
    overflow: hidden;
}



img.product {
    height: 100%;
    width: auto;

    margin: 0 auto;
    text-align: center;
    position: relative;
    display: block;
}


span.quantity-btn {
    position: relative;
    width: 25%;
    padding-top: 8px;
    padding-bottom: 8px;
    display: inline-block;
    text-align: center;
    margin: 0 auto;
    border: 1px solid rgba(0, 0, 0, 0.137);
    background-color: white;
}

span.num {
    border: none;
    
}



button {
    display: relative;
}

.shop-main {
    position: fixed;
    top: 0; left: 0; right: 0; bottom: 0;
    height: 100vh;
    overflow-y: scroll;
  
}

.shop {
    position: relative;
    background-color: rgb(248, 248, 248);
    text-align: center;
    position: relative;
    display: block;
    color: black;
    padding-bottom: 100px;
    width: 100%;
}

.call-to-action-text {
    position: relative;
  
    padding: 10px;
    width: 50%;
    margin: 20px auto;
    font-weight: lighter;
    color: #7b4397;
    
}

.product-name {
    padding-top: 4px;
    padding-bottom: 80px;
    text-align: left;
    width: 96%;
    margin: 0 auto;
}

.text-left {
    text-align: left;
}

.upcase {
    text-transform: uppercase;
}

.let-spacing-5 {
    letter-spacing: -0.3px;
}

.font-size-85pt {
    font-size: 9.5pt;
}

.font-size-9pt {
    font-size: 9pt;
    font-family: "Sanz-Thin", sans-serif;
}

.font-100 {
    font-weight: 100;
}

.font-weight-200 {
    font-weight: 200;
}

.font-300 {
    font-weight: 300;
}
.font-400 {
    font-weight: 500;
}

.product-image-gallery {
    margin-top: 1000px;
    padding-top: 310px;
    width: 800px;
    margin: 0 auto;
    position: relative;
    display: block;
    background: linear-gradient(transparent,  black);
}

.category-image {
    position: fixed;
    overflow: hidden;
    top: 0;
    max-height: 600px;

}

.width-full {
    width: 100%;
    margin: 0 auto;
}

.bname {
    background-clip: text;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    background: white;
   font-family: "Sanz-Reg", sans-serif;
   margin-top: 5px;
}

.bname::after {
    background: linear-gradient(306deg, black, #7b4397, black, #e72f60);

}


.main-product-name {
    font-size: 30px;
    display: inline-block;
    position: relative;
    width: fit-content;
    font-family: "Sanz-Thin", sans-serif;
}

.black-back {
    width: 100%;
    background: black;
}

.gray-back {
    width: 100%;
    background: black;

}



    .category-image {
        position: fixed;
        overflow: hidden;
        top: 50px;
        max-height: 150px;
        width: 100%;
    }

    .product-container {
        display: inline-block;
        position: relative;
        width: 50%;
        vertical-align: top;
        margin: 0 auto;
        margin-top: 0;
        text-align: center;
    }

    .product-image-gallery {
        margin-top: 0px;
        padding-top: 10px;
        margin: 0 auto;
        width: 100%;
        text-align: center;
        position: relative;
        background: linear-gradient(transparent, black, black, black);
        background: rgb(248, 248, 248);
    
    }


    .three {
        width: 30%;
    }

    .two {
        width: 50%
    }
    
    .cart-title {
        z-index: 99999;
        font-weight: 500;
        font-size: 10pt;
        top: 0;
    }

    .category-label {
        background-color: rgb(250, 250, 250);
        font-size: 14px;
        padding: 15px;
        padding-top: 15px;
        text-transform: uppercase;
        color: rgba(0, 0, 0, 0.616);
        color: #7b4397;
        text-align: left;
        position: sticky;
        top: 50px;
        z-index: 1000;
        letter-spacing: .01em;
        font-family: "Sanz-HeavyCond", sans-serif;
      

    }


    .category-label-2 {
        background-color: rgb(250, 250, 250);
        font-size: 14px;
        padding: 15px;
        padding-top: 15px;
        text-transform: uppercase;
        color: rgba(0, 0, 0, 0.616);
        color: #7b4397;
        text-align: left;
        position: sticky;
        top: 0px;
        z-index: 1000;
        letter-spacing: .01em;
        font-family: "Sanz-HeavyCond", sans-serif;
    }

    .category-label-2:hover {
        cursor: pointer;
    }

    div.cart-item-name.link:hover {
        cursor: pointer;
    }

    .category-label-3 {
        background-color: rgb(250, 250, 250);
        font-size: 11px;
        padding: 15px;
        padding-top: 15px;
        text-transform: uppercase;
        color: rgba(0, 0, 0, 0.616);
        color: #7b4397;
        text-align: left;
        position: sticky;
        top: 50px;
        z-index: 1000;
        letter-spacing: .01em;
        font-family: "Sanz-HeavyCond", sans-serif;
    }

    .category-label-3 a {
        font-synthesis: none;
        -moz-font-feature-settings: "kern";
        -webkit-font-smoothing: antialiased;
        color: transparent;
        background: linear-gradient(to right, #eb4191, #7c4a8a);
        background-clip: text;
        -webkit-background-clip: text;
        padding-bottom: 2px;
    }


    /* Hide scrollbar for Chrome, Safari and Opera */
*::-webkit-scrollbar {
    display: none;
  }
  
  /* Hide scrollbar for IE, Edge and Firefox */
  * {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }

    .selected-product {
        padding-top: 10px;
    }



    .category-label-cart {
        background-color: rgb(250, 250, 250);
        font-size: 7pt;
        padding: 10px;
        padding-top: 15px;
        text-transform: uppercase;
        color: rgba(0, 0, 0, 0.616);
        text-align: left;
        position: relative;
        z-index: 10;

    }

    .add-to-cart {
        position: relative;
        margin: 0 auto;
        width: 100%;
        bottom: 0;
        padding-top: 0px;
        padding-bottom: 0px;
        height: 50px;
        border: none;
        border-radius: 50px;
        color: white;
        transition: .7s;
        background: linear-gradient(to right, #7b4397, #e72f60);
        background: transparent;
        box-shadow: rgba(94, 87, 95, 0.2) 0px 7px 29px 0px;
        box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px;
    }

    .add-to-cart::after {
        z-index: -100000;
        border-radius: 50px;
        content: '';
        display: block;    
        position: absolute;
        top: -1px;
        left: -1px;
        width: calc(100% + 2.5px);
        height: calc(100% + 2px);
        transform: translateZ(-1px);
        background: linear-gradient(306deg, black, #7b4397, black, #e72f60);
        background-size: 400% 400%;
     
        -webkit-animation: WelcomePageGradient 4s ease infinite;
        -moz-animation: WelcomePageGradient 4s ease infinite;
        animation: WelcomePageGradient 4s ease infinite;
     }

     .section {
        margin-top: 10px;
        padding-top: 30px;
        height: 60vh;
        position: relative;
        display: block;
        background: transparent;
        text-align: center;
        overflow: hidden;
    }

     img.product-section-image {
        margin: 0 auto;
        position: absolute;
        bottom: -4%;
        left: 0;
        transform: unset;
        z-index: 99;
        width: 100%;
     }

     .section-call-to-action {
        width: 80%;
        margin: 0 auto;
        text-align: center;
        margin-bottom: 30px;
        position: absolute;
        top: 44%; left: 50%; transform: translate(-50%, -50%);
        z-index: 999;
        padding: 20px;

     }

     .call-to-action, .call-to-action i {
        color: #b8afba;
        color: rgb(191, 90, 242);
        text-transform: uppercase;
        font-size: 9.5pt;

        margin: 0 auto;
        z-index: 99999;
        font-synthesis: none;
        -moz-font-feature-settings: "kern";
        -webkit-font-smoothing: antialiased;
        color: transparent;
        background: linear-gradient(to bottom right, #eb4191, #a563b7);
        background-clip: text;
        -webkit-background-clip: text;
 
     }

     button.add-to-cart-small {
        position: absolute;
        top: 45vw;
        text-align: center;
        vertical-align: middle;
        right: 0px;
        width: 40px;
        height: 30px;
        background: transparent;
        z-index: 99999;
     }
     

     button.add-to-cart-small::after {
        z-index: -100000;
        border-radius: 50px;
        content: '';
        display: block;    
        position: absolute;
        top: -1px;
        left: -1px;
        width: calc(100% + 2.5px);
        height: calc(100% + 2px);
        transform: translateZ(-1px);
        background: linear-gradient(306deg, black, #7b4397, black,  #e72f60);
        background-size: 400% 400%;
     
        -webkit-animation: WelcomePageGradient 4s ease infinite;
        -moz-animation: WelcomePageGradient 4s ease infinite;
        animation: WelcomePageGradient 4s ease infinite;
     }

     .add-text {
         color: white;
         position: absolute;
         top: 50%; left: 50%;
         transform: translate(-50%, -50%);
         font-size: 9.5pt;
         font-weight: 300;
     }

    .add-to-cart-container {
        position: fixed;
        bottom: 10px;
        width: 80%;
        left: 50%; transform: translate(-50%);
        background-color: transparent;
        z-index: 999;
    }

    .add-to-cart-container5 {
        position: fixed;
        bottom: 10px;
        width: 80%;
        right: unset;
        left: 50%; transform: translate(-50%);
        background-color: transparent;
        z-index: 999999999999999;
    }




    button {
        border: 1px solid transparent;
        outline: none;
        box-shadow: none;
        width: 90%;
        height: 40px;
        background: linear-gradient(to right, #7b4397, #e72f60);
        background-size: 100% 200%;
        background: transparent;
        background-position: 0 var(--modal-button-gradient-pos);
        color: black;
        margin: 20px;
        font-weight: 300;
        font-size: 8.5pt;
    }

 

    .bname {
        background: linear-gradient(306deg, black, #7b4397, black, #e72f60);
        background-size: 300% 300%;
     
        -webkit-animation: WelcomePageGradient 6s ease infinite;
        -moz-animation: WelcomePageGradient 6s ease infinite;
        animation: WelcomePageGradient 6s ease infinite;
        background-clip: text;
        -webkit-background-clip: text;
        color: transparent;
     
        font-size: 12px;
        font-weight: 500;
    }

    .brand-name {
        font-size: 20px;
        font-weight: 300;
        text-transform: uppercase;
 
        font-family: "Sanz-ThinCond", sans-serif;
    }

    .cost {
        font-size: 18px;
        font-weight: 400;
        margin-top: 10px;
        display: inline-block;
        font-family: "Sanz-Thin", sans-serif;
    }

    .product-desc {
        margin-top: 10px;

        font-family: "Sanz-RegCond", sans-serif;
    }



.mobile-navbar-top {
    height: 50px;
    position: relative;
    background-color: white;
    display: block;
}

.view-by {
    text-transform: uppercase;
    letter-spacing: -0.5px;
    font-size: 9pt;
    text-align: left;
    padding-left: 25px;
    display: inline-block;
    font-weight: 300;
    width: 90%;
}

.selected {
    font-size: 9.5;
}

.space-10 {
    padding-left: 12px;
    padding-bottom: 20px;
    display: none;

}

.product-category {
    font-size: 11pt;
    
    padding: 20px;
}


.width-full {
    width: 100%;
    margin: 0 auto;
}



.c {
    text-align: center;
}


.no-pad {
    padding-bottom: 30px;
}

.trans-back {
    background-color: transparent;
}

.link {
    color: #7b4397;
    font-weight: 400;
}

.pad-20 {
    padding-bottom: 40px;
}

.pad-5 {
    padding-top: 5px;
}

.border-top {
    border-top: 1px solid rgba(0, 0, 0, 0.247);
}

.border-bottom {
    border-bottom: 1px solid rgba(0, 0, 0, 0.247);
}

.light-box-shadow {
    box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px;
}

.pad-top-btm {
    padding-top: 8px;
    padding-bottom: 8px;

}

button.category-label {
    padding-left: 0;
    padding-right: 0;
    width: 90%;

}

.wht {
    color: rgba(255, 255, 255, 0.911);
}

.cost {
    color: rgba(22, 22, 22, 0.911);
}

.name {
    color: rgb(22, 22, 22);
    padding-top: 15px;
}

* {
    -webkit-tap-highlight-color: transparent;
}

.black-back {
    width: 100%;
    background: black;
}

.sanz-black {
    font-family: "Sanz-Black", sans-serif;
}

.sanz-heavy {
    font-family: "Sanz-Heavy", sans-serif;
}

.sanz-reg {
    font-family: "Sanz-Reg", sans-serif;
}

.sanz-thin {
    font-family: "Sanz-Thin", sans-serif;
}

.apple-h2 {
    color: #cecece;
    color: white;
    display: inline-block;
    max-width: unset;
    margin: 0 auto;
    position: relative;
    z-index: 3;
    pointer-events: none;
    font-size: 62px;
    line-height: .9;
    font-weight: 600;
    letter-spacing: -.03em;
    font-synthesis: none;
    -moz-font-feature-settings: "kern";
    -webkit-font-smoothing: antialiased;
    color: transparent;
    background: linear-gradient(to right, #eb4191, #7c4a8a);
    background-clip: text;
    -webkit-background-clip: text;
    width: fit-content;
    background-size: cover;
    text-transform: uppercase;
    background-size: auto, auto;
}

.apple-h3 {
    color: #cecece;
    color: white;
    max-width: 57.6470588235rem;
    margin: 0 auto;
    position: relative;
    z-index: 3;
    pointer-events: none;
    font-size: 55px;
    line-height: .51;
    font-weight: 600;
    letter-spacing: -.03em;
    font-synthesis: none;
    text-transform: uppercase;
    -moz-font-feature-settings: "kern";
    -webkit-font-smoothing: antialiased;
}



.gray-font {
    color: #cecece;
}

.purple-back {
    background: transparent;
    position: absolute;
    top: 56%;
    width: 60%;
    left: 50%; transform: translate(-50%);
    display: block;
    z-index: -10;
    box-shadow: 0px 0px 180px 30px #e43bfa;
    opacity: .44;
}

.pink-back {
    background: transparent;
    position: absolute;
    top: 62%;
    width: 60%;
    left: 50%; transform: translate(-50%);
    display: block;
    z-index: -10;
    box-shadow: 0px 0px 180px 30px #fa3bd1;
    opacity: .44;
}

.purple-back-frontpg {
    background: transparent;
    position: absolute;
    top: 56%;
    width: 60%;
    left: 50%; transform: translate(-50%);
    display: block;
    z-index: 0;
    box-shadow: 0px 0px 180px 30px #e43bfa;
    opacity: .44;
}

.pink-back-frontpg {
    background: transparent;
    position: absolute;
    top: 62%;
    width: 60%;
    left: 50%; transform: translate(-50%);
    display: block;
    z-index: 0;
    box-shadow: 0px 0px 180px 30px #fa3bd1;
    opacity: .44;
}

.inkbrush-image {
    width: unset;
    left: unset; transform: unset;
    margin-top: -15%;
    position: relative;
}

.fade-in-and-out {
    -webkit-animation: fade-in-and-out 4s ease-in-out infinite;
    -moz-animation: fade-in-and-out 4s  ease-in-out infinite;
    animation: fade-in-and-out 4s  ease-in-out infinite;
}

.fade-in-and-out2 {
    -webkit-animation: fade-in-and-out2 4s  ease-in-out infinite;
    -moz-animation: fade-in-and-out2 4s  ease-in-out infinite;
    animation: fade-in-and-out2 4s  ease-in-out infinite;
}

.height-60 {
    height: 60vh;

}

.apple-gray-back {
    background: #161617;
    width: 100%;
    padding: 20px;
    padding-top: 40px;
    padding-bottom: 140px;
    text-align: center;
    font-size: 28px;
    line-height: 1.6em;
    font-family: "Sanz-Thin", sans-serif;
}

.ink-gradient {
    color: transparent;
    background: linear-gradient(to right, #eb4191, #7c4a8a);
    background-clip: text;
    -webkit-background-clip: text;
    font-size: 29px;
    line-height: 1.6em;
    font-family: "Sanz-Thin", sans-serif;
}


.deeper-gray-font {
   color: #86868b;
   font-family: "Sanz-HeavyCond", sans-serif;
   line-height: 1.4285914286;
   letter-spacing: -0.016em;
   font-size: 16px;
   font-weight: 400;
}

.sanz-thin {
    font-family: "Sanz-Thin", sans-serif;
}

.inkbrush-text {
    position: absolute;
    left: 50%; transform: translate(-50%);
    width: 100%;
    padding-bottom: 0;
    margin-bottom: 0;
}

.sanz-regitalic {
    font-family: "Sanz-RegItalic", sans-serif;
}

a.purp-btn, a.purp-btn i {
    background: none;
    color: #7b4397;
    margin: 0 auto;
    text-transform: none;
    font-size: 16px;
    width: fit-content;
    font-weight: 400;
    border-radius: 0;
    margin-bottom: 20px;
}

button.purp-btn i { 
    font-weight: 400;
}

.no-caps {
    text-transform: none;
}

.abs-right {
    float: right;
    font-size: 16px;
}

.border-top-bottom {
    border-bottom: .5px solid #3a3939;


}

.discover-links {
    position: relative;
    top: 10px;
    text-align: center;
    width: 100%;
    font-family: "Sanz-Reg", sans-serif;
    font-size: 14px;
    line-height: 1.6em;
    margin: 0 auto;
    border-bottom: .5px solid #3a3939;
    padding-bottom: 10px;

}

.discover-link {
    padding: 7px;

}

.opacity-7 {
    opacity: .9;
    filter: grayscale(60%) blur(3px);
    
}

.black-bubble {
    margin-top: 10%;
    width: 100%;
    height: 320px;
    padding: 40px;
    left: unset;
    transform: unset;
    background: black;
    border-radius: 20px;
    position: relative;
    display: block;
    background-image: url(https://cosmetinkshop.s3.us-east-2.amazonaws.com/inbrush_work2.PNG);
    background-size: 100% auto;
    background-repeat: no-repeat;
    background-position: left top;
}

.bubble-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(230deg, transparent, transparent, rgba(0, 0, 0, 0.226), #7b43976b);
    z-index: 9;
    border-radius: 20px;
}

.font-14 {
    font-size: 18px;
    line-height: 1.4em;
}

.bottom {
    width: 80%;
    position: absolute;
    bottom: 14%;
    left: 5%;
    text-align: left;
    z-index: 99;
    font-size: 20px;
    font-family: "Sanz-Heavy", sans-serif;
}

.black-bubble img.bubble-image {
   position: relative;
   display: block;
   width: 100%;
}

.white-back {
    background: white;
}

.black-back {background: black}

.line3 {
    padding: 0px;
    position: absolute;
    top: 33%;
    color: #cecece;
    z-index: 99;
    font-size: 16px;
}

.yellow-back-frontpg {
    background: transparent;
    position: absolute;
    top: 68%;
    width: 60%;
    left: 50%; transform: translate(-50%);
    display: block;
    z-index: 0;
    box-shadow: 0px 0px 180px 30px #6a2f8cd1;
    opacity: .44;
}

.white-back-frontpg {
    background: transparent;
    position: absolute;
    top: 62%;
    width: 60%;
    left: 50%; transform: translate(-50%);
    display: block;
    z-index: 0;
    box-shadow: 0px 0px 180px 30px #d7d7d7b3;
    opacity: .34;
}

.gray-back {
    width: 100%;
    background: #f5f5f7;
    color: black;

}

.blk {
    color: black;
}

.name2 {
    font-family: "Sanz-Reg", sans-serif;
    margin-top: 9px;
}
.name2 span {
    font-family: "Sanz-Thin", sans-serif;
}
.cost2 {
    font-family: "Sanz-Reg", sans-serif;
}

.success-page {
    position: fixed;
    z-index: 9999999999999999999999999999;
    height: 100vh;
    width: 100%;
}

.abs-c {
  position: absolute;
  top: 40%; left: 50%;
  transform: translate(-50%, -50%);
}
.add-to-cart-container2 {
    position: fixed;
    bottom: 10px;
    width: 80%;
    left: 50%; transform: translate(-50%);
    background-color: transparent;
    z-index: 99999999999999999999999999999999999999999999999;
}

.shipping-container {
    position: fixed;
    top: 50px;
    width: 100vw;
    right: unset;
    height: 100vh;
    background: white;
    left: 0%;
    overflow-y: scroll;
    z-index: 999999999999999999999999;
    transition: .7s;
}

.inside-shipping {
    padding: 20px;
    min-height: 140vh;
}

select {
    font-size: 16px;
    padding: 12px;
    margin-top: 20px;
    background: none;
    padding: 12px;
    width: 100%;
    border-radius: 5px;
    border-width: 1px;
    margin-top: 20px;
    font-size: 16px;
    border: 1px solid rgba(0, 0, 0, 0.15);
}

label {
    font-size: 16px;
}

.checkout {
    z-index: 9999999999999999999999999999999999999999999;
    transition: 4s;
}

}

.email-test {
    font-style: italic;
    padding-top: 7px;
    color: red;
}

.detail-box {
    text-align: left;
}

.box-desc {
    font-size: 16px;
    line-height: 1.4em;
    font-family: "Sanz-Reg", sans-serif;
}

ul.wht {
    margin: 0;
    padding-left: 0;
    font-family: "Sanz-Reg", sans-serif;
}

.bold.wht {
    margin: 0;
    padding-left: 0;
    font-family: "Sanz-Heavy", sans-serif;
}

.footer {
    background: #e7e2e9;
    color: black;
    padding: 20px;
    font-size: 11px;
    font-synthesis: none;
    margin-top: 10px;
}

a.mailto {
    font-synthesis: none;
    -moz-font-feature-settings: "kern";
    -webkit-font-smoothing: antialiased;
    color: transparent;
    background: linear-gradient(to right, #eb4191, #7c4a8a);
    background-clip: text;
    -webkit-background-clip: text;
}

.red {color: rgba(255, 255, 255, 0.969)}