.mobile-navbar-top {
    position: fixed;
    width: 100%;
    height: 50px;
    top: 0;
    padding-top: 0px;
    z-index: 999999;
    background: transparent;
    color: white; 


}

i {
    color: white;
}


a.navbar-link-artists {
    position: relative;
    top: 50%;
    transform: translate(0, -50%);
    display: inline-block;
    color: black;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 8pt;
    font-weight: 400;
    text-decoration: none;
    vertical-align: middle;
    padding-right: 2em;
    z-index: 100000000;
    float: right;
    text-transform: uppercase;
}

.navbar-link-artists i {
    color: black;
    font-size: 11.5pt;
    font-weight: lighter;
}


a.navbar-logo {
    position: absolute;
    height: 55px;
    width: 58%;
    top: 0px;
    left: 50%;
    transform: translate(-50%);
    font-size: 13pt;
    font-weight: 300;
}


img.nav-logo {
    position: relative;
    top: 50%;
    left: 50%;
    max-width: 90%;
    max-height: 62px;
    transform: translate(-50%, -50%);
}

a {
    text-decoration: none;
}

.fold-menu-hidden {
    position: absolute;
    z-index: 2000000000000;
    width: 100%;
    height: 100vh;
    background: linear-gradient(to right, #7b4397f5, #e72f60f5);
    top: -110vh;
    left: 0; right: 0;
    transition: .4s;
}

.fold-menu {
    position: fixed;
    z-index: 2000000000000;
    height: 100vh;
    width: 100%;
    background-color: #161617f1;
    background: linear-gradient(to right, #7b4397f5, #e72f60f5);
    bottom: 0;
    left: 0; right: 0;
    top: 0;
    transition: .4s;
    text-align: center;
}

.menu-button {
    position: absolute;
    background: transparent;
    vertical-align: top;
    text-align: left;
    z-index: 999999999;
    width: 50px;
    height: 50px;
    margin-left: 7px;
    margin-top: 0;
    padding: 15px;
}   

.menu-button i {
    top: 0;
    font-size: 16.5pt;
}

.menu-overlay {
    z-index: 9999;
    background-color: rgba(116, 116, 116, 0.658);
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 101vh;
}

.fold-title {
        color: white;
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
        font-size: 10.5pt;
        font-weight: 400;
        padding: 0;
        padding-top: 15px;
        padding-left: 5px;
        padding-bottom: 10px;
        background-color: transparent;
        text-align: center;
}

.fold-title i {
    position: absolute;
    right: 20px;
    display: inline-block;
    font-size: 14pt;
    opacity: .8;
}

.blur {

    background: linear-gradient(rgba(0, 0, 0, 0.773), rgba(0, 0, 0, 0.249));
    backdrop-filter: blur(8px);
 
 
}

.cart-num {
    position: fixed;
    top: 8px;
    right:  14px;
    background-color: transparent;
    color: white;
    padding: 13px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 50%;
    font-size: 14px;
    z-index: 9999999;
}

.cart-num::after {
    z-index: -100000;
    border-radius: 50px;
    content: '';
    display: block;    
    position: absolute;
    top: -1px;
    left: -1px;
    width: calc(100% + 2.5px);
    height: calc(100% + 2px);
    transform: translateZ(-1px);
    background: linear-gradient(306deg, black, #7b4397, black, #e72f60);
    background-size: 400% 400%;
 
    -webkit-animation: WelcomePageGradient 4s ease infinite;
    -moz-animation: WelcomePageGradient 4s ease infinite;
    animation: WelcomePageGradient 4s ease infinite;
 }

/* Artist Fold */

.artist {
    display: inline-block;
    width: 50%;
    text-align: center;
    margin: 6px auto;
}

.artist:hover {
    opacity: .8;
}

.artist-link {
position: relative;
margin: 0 auto;
background-color: transparent;
}

.artist-link:hover {
   opacity: .8;
   cursor: pointer;
}

.artist-image {
   position: relative;
   width: 95%;
   background-color: transparent;
   transition: .2s;
   height: auto;
   overflow: hidden;
}

.artist-image-2 {
    width: 100%;
    position: relative;
    opacity: 1;
    transition: opacity .25s ease-in-out;
    -moz-transition: opacity .25s ease-in-out;
    -webkit-transition: opacity .25s ease-in-out;
 }

 .artist-position {
    font-size: 10pt;
    font-weight: 300;
    text-transform: uppercase;
    right: 0;
    padding: 0;
    padding-bottom: 0;
    padding-top: 0;
    position: relative;
    width: 100%;
    text-align: right;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: 300;
    background: -webkit-linear-gradient(0deg, rgb(192, 192, 192), white);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
 }

 a.instagram {
    display: none;
    background-color: transparent;
    font-size: 1em;
    font-weight: lighter;
    margin-top: -25px;
    padding-top: 0;
    padding: .01em;
    background-color: transparent;
 }


 .white {
     background-color: white;
     height: 54px;
 }

 .right-link {
     position: absolute;
     right: 20px;
     top: 10px;
     color: white;
 }

 .up-front {
     z-index: 9999;
     position: fixed;
 }

 .float-left:hover {
    cursor: pointer;
 }

 .cart-num:hover {
    cursor: pointer;
 }

 .category-label-menu {
    color: black;
    font-size: 28px;
 
    font-family: "Sanz-HeavyCond", sans-serif;
    line-height: 1.2em;
    margin-top: 18px;
    margin-left: 10px;
 
  
 }

 .gradientized {
    font-synthesis: none;
    -moz-font-feature-settings: "kern";
    -webkit-font-smoothing: antialiased;
    color: transparent;
    background: linear-gradient(to right, #eb4191, #7c4a8a);
    background-clip: text;
    -webkit-background-clip: text;
 }

 .gradientized i {
    font-synthesis: none;
    -moz-font-feature-settings: "kern";
    -webkit-font-smoothing: antialiased;
    color: transparent;
    background: linear-gradient(to right, #eb4191, #7c4a8a);
    background-clip: text;
    -webkit-background-clip: text;
 }

 .shop-category-links {
    display: block;
    width: 100vw;
    margin-top: 55px;
    white-space: nowrap;
    overflow: hidden;
    text-align: center;
}

.menu-body {
    margin: 40px;
    margin-top: 70px;
}


.category-link {
    display: inline-block;
    position: relative;
    margin: 0 auto; 
}

.block {
    display: block;
}

.subcategory-links {
    display: block;
    width: 100vw;
    overflow: hidden;
    text-align: center;
}

.brand-link {
    font-weight: 300;
    width: fit-content;
    text-transform: uppercase;
    font-size: 7.5pt;
    padding: 9px;
    padding-left: 12px;
    padding-right: 12px;
    border-radius: 20px;
}

.pos-absolute {
    position: fixed;
    z-index: 999999999999;
    background: white;
    top: 0; height: 100vh;
    width: 100%;

}

.bubble {
    margin: 7px;
    color: white;
    font-weight: 300;
    width: fit-content;
    text-transform: uppercase;
    font-size: 7.5pt;
    padding: 9px;
    padding-left: 12px;
    padding-right: 12px;
    border-radius: 20px;
    background-color: rgba(255, 255, 255, 0.158);
}

.hidden {
    display: none;
}

.hamburger-one {
    position: fixed;
    height: 1px;
    color: white;
    background: white;
    width: 18px;
    top: 18px;
    z-index: 99999;
    transition: .6s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.rotate-one {
    position: absolute;
    height: 1px;
    color: black;
    background: black;
    width: 18px;
  
    z-index: 999999999999999999999;
    rotate: 45deg;
    transition: .6s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.hamburger-two {
    position: fixed;
    height: 1px;
    color: white;
    background: white;
    width: 18px;
    top: 26px;
    z-index: 999999;
    transition: .6s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.rotate-two {
    position: absolute;
    display: block;
    height: 1px;
    color: black;;
    background: black;
    width: 18px;
  
    z-index: 9999999999999999999999999999999999999999;
    rotate: -45deg;
    transition: .6s cubic-bezier(0.075, 0.82, 0.165, 1);
}

@media (max-width: 800px) {
    .mobile-navbar-top {
        position: fixed;
        width: 100%;
        height: 50px;
        top: 0;
        padding-top: 0px;
        z-index: 999999;
        background: transparent;
        color: white; 
    
    
    }
    
    i {
        color: white;
    }
    
    
    a.navbar-link-artists {
        position: relative;
        top: 50%;
        transform: translate(0, -50%);
        display: inline-block;
        color: black;
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
        font-size: 8pt;
        font-weight: 400;
        text-decoration: none;
        vertical-align: middle;
        padding-right: 2em;
        z-index: 100000000;
        float: right;
        text-transform: uppercase;
    }
    
    .navbar-link-artists i {
        color: black;
        font-size: 11.5pt;
        font-weight: lighter;
    }
    
    
    a.navbar-logo {
        position: absolute;
        height: 55px;
        width: 58%;
        top: 0px;
        left: 50%;
        transform: translate(-50%);
        font-size: 13pt;
        font-weight: 300;
    }
    
    
    img.nav-logo {
        position: relative;
        top: 50%;
        left: 50%;
        max-width: 90%;
        max-height: unset;
        transform: translate(-50%, -50%);
    }
    
    a {
        text-decoration: none;
    }
    
    .fold-menu-hidden {
        position: absolute;
        z-index: 2000000000000;
        width: 100%;
        height: 100vh;
        background: linear-gradient(to right, #7b4397f5, #e72f60f5);
        top: -110vh;
        left: 0; right: 0;
        transition: .4s;
    }
    
    .fold-menu {
        position: fixed;
        z-index: 2000000000000;
        height: 100vh;
        width: 100%;
        background-color: #161617f1;
        background: linear-gradient(to right, #7b4397f5, #e72f60f5);
        bottom: 0;
        left: 0; right: 0;
        top: 0;
        transition: .4s;
        text-align: center;
    }
    
    .menu-button {
        position: absolute;
        background: transparent;
        vertical-align: top;
        text-align: left;
        z-index: 999999999;
        width: 70px;
        height: 70px;
        margin-left: 0px;
        margin-top: 0;
        top: 0;
        padding: 15px;
    }   
    
    .menu-button:hover {
        cursor: pointer;
    }
    .menu-button i {
        top: 0;
        font-size: 16.5pt;
    }
    
    .menu-overlay {
        z-index: 9999;
        background-color: rgba(116, 116, 116, 0.658);
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 101vh;
    }
    
    .fold-title {
            color: white;
            font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
            font-size: 10.5pt;
            font-weight: 400;
            padding: 0;
            padding-top: 15px;
            padding-left: 5px;
            padding-bottom: 10px;
            background-color: transparent;
            text-align: center;
    }
    
    .fold-title i {
        position: absolute;
        right: 20px;
        display: inline-block;
        font-size: 14pt;
        opacity: .8;
    }
    
    .blur {
    
        background: linear-gradient(rgba(0, 0, 0, 0.773), rgba(0, 0, 0, 0.249));
        backdrop-filter: blur(8px);
     
     
    }
    
    .cart-num {
        position: fixed;
        top: 8px;
        right:  14px;
        background-color: transparent;
        color: white;
        padding: 13px;
        padding-top: 10px;
        padding-bottom: 10px;
        border-radius: 50%;
        font-size: 9.5pt;
        z-index: 9999999;
    }
    
    .cart-num::after {
        z-index: -100000;
        border-radius: 50px;
        content: '';
        display: block;    
        position: absolute;
        top: -1px;
        left: -1px;
        width: calc(100% + 2.5px);
        height: calc(100% + 2px);
        transform: translateZ(-1px);
        background: linear-gradient(306deg, black, #7b4397, black, #e72f60);
        background-size: 400% 400%;
     
        -webkit-animation: WelcomePageGradient 4s ease infinite;
        -moz-animation: WelcomePageGradient 4s ease infinite;
        animation: WelcomePageGradient 4s ease infinite;
     }
    
    /* Artist Fold */
    
    .artist {
        display: inline-block;
        width: 50%;
        text-align: center;
        margin: 6px auto;
    }
    
    .artist:hover {
        opacity: .8;
    }
    
    .artist-link {
    position: relative;
    margin: 0 auto;
    background-color: transparent;
    }
    
    .artist-link:hover {
       opacity: .8;
       cursor: pointer;
    }
    
    .artist-image {
       position: relative;
       width: 95%;
       background-color: transparent;
       transition: .2s;
       height: auto;
       overflow: hidden;
    }
    
    .artist-image-2 {
        width: 100%;
        position: relative;
        opacity: 1;
        transition: opacity .25s ease-in-out;
        -moz-transition: opacity .25s ease-in-out;
        -webkit-transition: opacity .25s ease-in-out;
     }
    
     .artist-position {
        font-size: 10pt;
        font-weight: 300;
        text-transform: uppercase;
        right: 0;
        padding: 0;
        padding-bottom: 0;
        padding-top: 0;
        position: relative;
        width: 100%;
        text-align: right;
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
        font-weight: 300;
        background: -webkit-linear-gradient(0deg, rgb(192, 192, 192), white);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
     }
    
     a.instagram {
        display: none;
        background-color: transparent;
        font-size: 1em;
        font-weight: lighter;
        margin-top: -25px;
        padding-top: 0;
        padding: .01em;
        background-color: transparent;
     }
    
    
     .white {
         background-color: white;
         height: 54px;
     }
    
     .right-link {
         position: absolute;
         right: 20px;
         top: 10px;
         color: white;
     }
    
     .up-front {
         z-index: 9999;
         position: fixed;
     }
    
    
     .category-label-menu {
        color: black;
        font-size: 28px;
     
        font-family: "Sanz-HeavyCond", sans-serif;
        line-height: 1.2em;
        margin-top: 18px;
        margin-left: 10px;
     
      
     }
    
     .gradientized {
        font-synthesis: none;
        -moz-font-feature-settings: "kern";
        -webkit-font-smoothing: antialiased;
        color: transparent;
        background: linear-gradient(to right, #eb4191, #7c4a8a);
        background-clip: text;
        -webkit-background-clip: text;
     }
    
     .gradientized i {
        font-synthesis: none;
        -moz-font-feature-settings: "kern";
        -webkit-font-smoothing: antialiased;
        color: transparent;
        background: linear-gradient(to right, #eb4191, #7c4a8a);
        background-clip: text;
        -webkit-background-clip: text;
     }
    
     .shop-category-links {
        display: block;
        width: 100vw;
        margin-top: 55px;
        white-space: nowrap;
        overflow: hidden;
        text-align: center;
    }
    
    .menu-body {
        margin: 40px;
        margin-top: 70px;
    }
    
    
    .category-link {
        display: inline-block;
        position: relative;
        margin: 0 auto; 
    }
    
    .block {
        display: block;
    }
    
    .subcategory-links {
        display: block;
        width: 100vw;
        overflow: hidden;
        text-align: center;
    }
    
    .brand-link {
        font-weight: 300;
        width: fit-content;
        text-transform: uppercase;
        font-size: 7.5pt;
        padding: 9px;
        padding-left: 12px;
        padding-right: 12px;
        border-radius: 20px;
    }
    
    .pos-absolute {
        position: fixed;
        z-index: 999999999999;
        background: white;
        top: 0; height: 100vh;
        width: 100%;
    
    }
    
    .bubble {
        margin: 7px;
        color: white;
        font-weight: 300;
        width: fit-content;
        text-transform: uppercase;
        font-size: 7.5pt;
        padding: 9px;
        padding-left: 12px;
        padding-right: 12px;
        border-radius: 20px;
        background-color: rgba(255, 255, 255, 0.158);
    }
    
    .hidden {
        display: none;
    }
    
    .hamburger-one {
        position: fixed;
        height: 1px;
        color: white;
        background: white;
        width: 18px;
        top: 18px;
        z-index: 99999;
        transition: .6s cubic-bezier(0.075, 0.82, 0.165, 1);
    }
    
    .rotate-one {
        position: absolute;
        height: 1px;
        color: black;
        background: black;
        width: 18px;
      
        z-index: 999999999999999999999;
        rotate: 45deg;
        transition: .6s cubic-bezier(0.075, 0.82, 0.165, 1);
    }
    
    .hamburger-two {
        position: fixed;
        height: 1px;
        color: white;
        background: white;
        width: 18px;
        top: 26px;
        z-index: 999999;
        transition: .6s cubic-bezier(0.075, 0.82, 0.165, 1);
    }
    
    .rotate-two {
        position: absolute;
        display: block;
        height: 1px;
        color: black;;
        background: black;
        width: 18px;
      
        z-index: 9999999999999999999999999999999999999999;
        rotate: -45deg;
        transition: .6s cubic-bezier(0.075, 0.82, 0.165, 1);
    }
}