.App {
    -webkit-animation: fade-in .7s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
    animation: fade-in .7s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

* {
    -webkit-tap-highlight-color: transparent;
}

@font-face {
    font-family: "Sanz-RegCond";
    src: local("Sanzettica-RegCond"), 
    url(/static/media/Sanzettica-RegCond.a7079513.ttf) format("truetype");
   }

   @font-face {
    font-family: "Sanz-Reg";
    src: local("Sanzettica-Reg"), 
    url(/static/media/Sanzettica-Reg.bf36643a.ttf) format("truetype");
   }
  
   @font-face {
    font-family: "Sanz-ThinCond";
    src: local("Sanzettica-ThinCond"), 
    url(/static/media/Sanzettica-ThinCond.2ca5a4fa.ttf) format("truetype");
   }

   @font-face {
    font-family: "Sanz-Thin";
    src: local("Sanzettica-Thin"), 
    url(/static/media/Sanzettica-Thin.888509e4.ttf) format("truetype");
   }

   @font-face {
    font-family: "Sanz-ThinItalic";
    src: local("Sanzettica-ThinItalic"), 
    url(/static/media/Sanzettica-ThinItalic.6e407c72.ttf) format("truetype");
   }


   @font-face {
    font-family: "Sanz-RegItalic";
    src: local("Sanzettica-RegItalic"), 
    url(/static/media/Sanzettica-RegItalic.ae2f4841.ttf) format("truetype");
   }
  
   @font-face {
    font-family: "Sanz-Heavy";
    src: local("Sanzettica-Heavy"), 
    url(/static/media/Sanzettica-Heavy.4c7905ce.ttf) format("truetype");
   }
  
   @font-face {
    font-family: "Sanz-Black";
    src: local("Sanzettica-BlackCond"), 
    url(/static/media/Sanzettica-BlackCond.67d3c245.ttf) format("truetype");
   }
  
   @font-face {
    font-family: "Sanz-Thin-Italic";
    src: local("Sanzettica-ThinItalic"), 
    url(/static/media/Sanzettica-ThinItalic.6e407c72.ttf) format("truetype");
   }

   .scale-out-ver-top {
	-webkit-animation: scale-out-ver-top 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
	        animation: scale-out-ver-top 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
}

   .scale-up-ver-top {
	-webkit-animation: scale-up-ver-top 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	        animation: scale-up-ver-top 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}


@-webkit-keyframes scale-up-ver-top {
    0% {
      transform: scaleY(0.4);
      transform-origin: 100% 0%;
    }
    100% {
      transform: scaleY(1);
      transform-origin: 100% 0%;
    }
  }
  @keyframes scale-up-ver-top {
    0% {
      transform: scaleY(0.4);
      transform-origin: 100% 0%;
    }
    100% {
      transform: scaleY(1);
      transform-origin: 100% 0%;
    }
  }

  @-webkit-keyframes scale-out-ver-top {
    0% {
      transform: scaleY(1);
      transform-origin: 100% 0%;
      opacity: 1;
    }
    100% {
      transform: scaleY(0);
      transform-origin: 100% 0%;
      opacity: 1;
    }
  }
  @keyframes scale-out-ver-top {
    0% {
      transform: scaleY(1);
      transform-origin: 100% 0%;
      opacity: 1;
    }
    100% {
      transform: scaleY(0);
      transform-origin: 100% 0%;
      opacity: 1;
    }
  }
  
  

.front-page-vid-container {
    position: relative;
    height: 100%;
    max-height: 100vh;
    overflow: none;
  }

  video.fit-abs {

  }
  
  .front-page-vid-overlay {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(rgba(0, 0, 0, 0.68), transparent, rgba(0, 0, 0, 0.226), transparent);
    background: none;
  }

  * {
    color: black;
    color: rgba(0, 0, 0, 0.616);
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    margin: 0;
}

input.code {
    margin-top: 10px;
    margin-right: 10px;
    padding: 7px;
    font-size: 16px;
  }
  
  .question {
    font-style: italic;
    color: rgb(128, 0, 94);
    margin-top: 10px;
    padding: 7px;
    padding-left: 0;
    font-weight: 500;
  }

  button.enter {
  
        color: inherit;
        border: none;
      margin: 10px auto;
      background-color: black;
      color: white;
      padding: 10px;
    
        outline: inherit;
    
  }

.entry-text-low {
    position: absolute;
    font-size: 14px;
    line-height: 1.47059;
    font-weight: 300;
    letter-spacing: -0.5px;
    font-family: "SF Pro Text","SF Pro Icons","Helvetica Neue","Helvetica","Arial",sans-serif;
    width: 100%;
    text-align: left;
    top: 70%;
    color: white;
  
    font-size: 12px;
    
    line-height: 1.6;
    font-weight: 200;
    text-align: center;
    letter-spacing: 1.2px;

    width: 100%;
    color: white;
    z-index: 200;

  }

  .top70 {
      top: 70%;

  }
  .black-friday-special {
    font-weight: 900;
    color: white;
    z-index: 200;
    display: block;
    font-size: 28px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: lighter;
    margin: 0 auto;
    position: relative;
    text-align: center;
    width: 100%;
  
    top: 60%;
    line-height: 1.05em;
    padding-top: 5px;
    padding-bottom: 8px;
    -webkit-font-smoothing: antialiased;
    left: 50%; transform: translate(-50%);
    color: white;
    font-weight: 100;
    font-size: 22=4px;
    line-height: 1.47059;
    font-weight: lighter;
    letter-spacing: 0.5px;
    font-family: "SF Pro Text","SF Pro Icons","Helvetica Neue","Helvetica","Arial",sans-serif;
    width: 100%;
 }

 .pad-left-5-p {
     padding-left: 5%;
 }



 .uppercase {
     text-transform: uppercase;
 }

 .inline-block {
    width: 50%;
    display: inline-block;
 }

 .italic-font {
     font-style: italic;
     text-transform: capitalize;
 }


img.landing-page-image {
    position: fixed;
    max-height: 101vh;
    overflow: hidden;
    top: 0;
    left: 0;
    width: 100%;
    z-index: -10;
    opacity: .6;
    background-color: transparent;
}



.front-page-title {
    font-size: 8.8vh;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: lighter;
    margin: 0 auto;
    position: absolute;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    top: 46%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    z-index: 90;
    width: 100%;
    color: white;
    background-color: transparent;
}

.front-page-logo {
    width: 100%;
}

.opacity-overlay {
    z-index: -300;
    background-color: rgb(0, 0, 0);
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 101vh;
}

.mobile-navbar {
    position: fixed;
    bottom: 0em;
    display: block;
    margin: 0 auto;
    margin-top: 2.5em;
    padding-top: 0em;
    padding-top: 0;
    padding-bottom: .6em;
    background: white;
    text-align: center;
    width: 100%;
    z-index: 60;
    border-top: none;
    background-color: white;
}

i {
 background-color: transparent;
 color: black;
 font-weight: inherit;
 font-style: inherit;
}

i.fa-star {
    color: white;
}

.mobile-navbar-link {
    display: inline-block;
    width: 16%;
    padding: 2%;
    padding-top: 1.2em;
    padding-bottom: 1em;

    font-size: 8pt;
    font-weight: 400;
    background-color: rgb(19, 19, 19);
    background-color: transparent;
    margin-left: .3em;
    margin-right: .3em;
    margin: 0 auto;
    border-radius: 7px;
    color: white;
    opacity: 1;
    text-decoration: none;
    vertical-align: top;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.mobile-navbar-link i {
    font-size: 20pt;
    padding: 4px;
    font-weight: 400;
}

.right-link i {
    color: transparent;
    font-size: 15pt;
}

.test-font {
    color: black;
}

.get-started {
    position: absolute;
    top: 50%;
    transform: translate(-50%);
    left: 50%;
    display: block;
    width: 160px;
    height: 52px;
    margin: 20px auto;
    vertical-align: middle;
    font-size: 40px;
    color: white;
    padding: 0px;
    text-align: center;
    text-transform: uppercase;
    font-size: 13px;
    font-weight: 500;
    font-size: 15px;
    -webkit-appearance: none;
    transition: .4s;
    border-image: linear-gradient(to right, #005e7f, #61b6cd);
    border-radius: 50px;
    background-size: 100%;
    background: white;
    border: 2px transparent;
    border-radius: 30px;
    background-origin: border-box;
    background-clip: content-box, border-box;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    z-index: 0;
  }
  
  .get-started::after {
    z-index: -100;
    border-radius: 50px;
    content: '';
    display: block;    
    position: absolute;
    top: -1px;
    left: -1px;
    width: calc(100% + 2.5px);
    height: calc(100% + 2px);
    background: linear-gradient(#61b6cd 35%, #005e7f 45%);
    background: linear-gradient(to right, #7b4397, #e72f60);
    background-size: 100% 200%;
    background-position: 0 var(--modal-button-gradient-pos);
    transform: translateZ(-1px);
  }

  .entry-btn {
    position: absolute;
    top: 46%;
    transform: translate(0, -50%);
    display: block;
    width: 100%;
    height: 52px;
    margin: 15px auto;
    vertical-align: middle;
    font-size: 40px;
    color: white;
    padding: 0px;
    text-align: center;
    text-transform: uppercase;

    font-size: 8.5pt;
    font-weight: 400;
    font-family: Helvetica_Regular,Arial,sans-serif;
    -webkit-appearance: none;
    transition: .4s;
    border-radius: 50px;
    background-size: 100%;
    background: transparent;
    border: 2px transparent;
    border-radius: 30px;
    background-origin: border-box;
    background-clip: content-box, border-box;
    z-index: 0;
    text-decoration: underline;
  }
  
  span.btn-text {
    position: absolute;
    width: 100%;
    left: 0;
    top: 51%;
    transform: translate(0, -50%);
    color: white;
  }
  
  a.get-started {
   font-weight: 600;
   font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
   
  }

  .menu {
        position: fixed;
        display: block;
        color: rgb(255, 255, 255);
        width: 70%;
        border: none;
        top: 0em;
        opacity: 1;
        background-color: white;
        background-color: transparent;
        box-shadow: none;
        text-align: left;
        right: 0;
        height: 100vh;
        z-index: 9999000;
        transition: .4s cubic-bezier(0.6, -0.28, 0.735, 0.045);
  }

  .page {
    position: relative;
    padding: 0px;
    padding-top: 47px;
    margin: 0;
    padding-left: 10px;
    padding-right: 10px;
    background-color: white;
    background-color: transparent;
}

.full-products {
    margin-top: 50px;
}

.full-background {
    background-color: white;
    background-color: transparent;
    z-index: -100;
    top: 0;
    left: 0;
    right: 0;
    margin: 0;
    min-height: 100vh;
}

.artist-page-title {
    font-size: 14pt;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: lighter;
    padding: .2em;
    padding-left: 0;
    border-bottom: 1px solid #3a3939;
    color: white;
    vertical-align: middle;
    width: 100%;
 }

 a.read-more-2 {
    font-weight: 400;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 9pt;
    color: #666;
     padding: 0;
    display: inline-block;
    z-index: 1;
 }
 
 div.read-more-2 {
     font-weight: 400;
     font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
     font-size: 11pt;
     color: #666;
      padding: 0;
      margin-top: -3.2em;
     text-align: right;
     z-index: 1;
  }

  .PMU-detail-page-dropped {
  
    color: white;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 11pt;
    font-weight: 300;
    line-height: 1.4em;
    max-width: 100%;
 }

 .PMU-detail-hidden {
     color: white;
     font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
     font-size: 11pt;
     line-height: 1.4em; 
     font-weight: 300;
     height: 2.8em;
     overflow: hidden;
     text-overflow: ellipsis;
     max-width: 100%;
 }

 a.read-less {
    width: 0;
    display: none;
    color: transparent;
 }

 .schedule-btn {
    position: relative;
    display: block;
    width: 88%;
    padding: 20px;
    margin: 0px auto;
    margin-top: 30px;
    vertical-align: middle;
    font-size: 40px;
    color: white;
    text-align: center;
    text-transform: uppercase;
    font-size: 13px;
    -webkit-appearance: none;
    transition: .4s;
      border-radius: 50px;
       border-radius: 30px;
      background-size: 100%;
      background: transparent;
      border: 2px transparent;
      background-origin: border-box;
      background-clip: content-box, border-box;
      z-index: 1;
      font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  }

.schedule-btn::after {
    z-index: -1;
    border-radius: 50px;
    content: '';
    display: block;    
    position: absolute;
    top: -1px;
    left: -1px;
    width: calc(100% + 2.5px);
    height: calc(100% + 2px);
    background: linear-gradient(to right, #7b4397, #e72f60);
    background-size: 100% 200%;
    background-position: 0 var(--modal-button-gradient-pos);
    transform: translateZ(-1px);
}

/* Schedule */
.schedule-open {
    position: fixed;
    top: 0;
    bottom: 0;
    background: linear-gradient(to bottom right, #7b4397, #e72f60);
    height: 100vh;
    width: 100%;
    text-align: center;
    z-index: 999999999999999999999999999999;
}

.schedule-inside {
    position: absolute;
    top: 70px;
    left: 50%;
    transform: translate(-50%);
    width: 90%;
    margin: 0 auto;
}

iframe.schedule {
    position: relative;
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: 0px;
    height: 75vh;
    background-color: transparent;
    scrollbar-width: 0px;
    scrollbar-color: transparent;
    -webkit-scrollbar-color: transparent;
}

.schedule-close {
    position: absolute;
    top: 20px;
    font-size: 11pt;
    padding: 5px;
    text-transform: uppercase;
    color: white;
    left: 20px;
    text-align: left;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: 500;
    width: 100%;
    background-color: transparent;
}

.schedule-close:hover {
    cursor: pointer;
}

.schedule-close i {
    font-size: 11pt;
    font-weight: inherit;
}

.ysl {
    font-family: Helvetica_Regular,Arial,sans-serif;
    font-weight: 550;
    text-transform: uppercase;
    font-size: 11pt;
    -webkit-font-smoothing: antialiased;
}

img.match-height {
    z-index: -1;
    opacity: 1;
    max-height: unset;
    position: absolute;
    max-height: 93vh;
    height: 100%;
    max-width: unset;
    width: auto;
    top: 54px;
    left: 50%;
    transform: translate(-50%);
    overflow: hidden;
    background-color: transparent;

}

.main-page {
    position: relative;
    width: 100%;
    overflow: hidden;
    height: 100vh;
    padding-bottom: 100px;
    background-color: transparent;

}

.blk {
    color: black;
}

.blk i {
    color: black;
}

img {
  max-width: 100%;
}

.width-100 {
    width: 100%;
    display: block;
    margin: 0 auto;
    text-align: center;
}

img.width-100 {
    width: 480px;
}

.width-90 {
    width: 97%;

}

.width-full {
    width: 100%;
    height: auto;
    opacity: 1;
    text-align: center;
    background-color: linear-gradient(black, white, black);
    background-color: white;
}

.abs {
    position: absolute;
    top: 50%; left: 50%;
    transform: translate(-50%, -50%);
}



.overlay {
    position: absolute;
    z-index: 999;
    background-color: linear-gradient(to right, #7b4397, #e72f60);
}
 


.product-container {
    display: inline-block;
    position: relative;
    vertical-align: top;
    width: auto;
    margin-top: 5%;
    height: auto;
}

/* CART */
.cart-container {
    position: fixed;
    display: block;
    top: 50px;
    height: 100vh;
    background: white;
    width: 40%;
    z-index: 9;
    right: 0;
    transition: .4s;
    overflow-y: scroll;
    z-index: 999;
    text-align: center;
}


.float-left {
    position: absolute;
    left: 15px;
}

.cart-container.menu-button {
    position: fixed;
}

.menu-button-cart {
    position: fixed;
    z-index: 9999999999999999999999999;
    display: block;
    left: 10px;
    background: transparent;
}

.cart-scroll {
    position: relative;
    min-height: 120vh;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    overflow: hidden;
    overflow-y: scroll;
    padding-bottom: 20vh;
    padding-top: 0px;
    width: 96%;
    margin: 0 auto;
}


.cart-item {
    display: block;
    margin-top: 5px;
    width: 100%;
    background-color: rgb(248, 248, 248);
    text-align: center;
}

.cart-item-name {
    display: block;
    text-align: left;
    margin-left: 12%;
    font-weight: 500;
 
}
.cart-image {
    height: 100px;
    text-align: left;
    display: inline-block;
}

span.third {
    width: 30%;
    display: inline-block;
    text-align: center;
    margin: 0 auto;
    vertical-align: middle;
    font-size: 8pt;
    text-transform: uppercase;
    color: rgba(0, 0, 0, 0.616);
    padding-top: 10px;
    padding-bottom: 10px;
}
.left {
    text-align: left;
}

.right {
    text-align: right;
}

img.cart-image {
    text-align: left;
}

.product-image-box {
    display: block;
    height: 22vw;
    width: 22vw;
    margin: 0 auto;
    overflow: hidden;
}



img.product {
    height: 100%;
    width: auto;

    margin: 0 auto;
    text-align: center;
    position: relative;
    display: block;
}


span.quantity-btn {
    position: relative;
    width: 25%;
    padding-top: 8px;
    padding-bottom: 8px;
    display: inline-block;
    text-align: center;
    margin: 0 auto;
    border: 1px solid rgba(0, 0, 0, 0.137);
    background-color: white;
}

span.num {
    border: none;
    
}



button {
    display: relative;
}

.shop-main {
    position: fixed;
    top: 0; left: 0; right: 0; bottom: 0;
    height: 100vh;
    overflow-y: scroll;
   
}

.shop {
    position: relative;
    background-color: rgb(248, 248, 248);
    text-align: center;
    position: relative;
    display: block;
    color: black;
    padding-bottom: 100px;
    width: 100%;
}

.call-to-action-text {
    position: relative;
  
    padding: 10px;
    width: 50%;
    margin: 20px auto;
    font-weight: lighter;
    color: #7b4397;
    
}

.product-name {
    padding-top: 4px;
    padding-bottom: 80px;
    text-align: left;
    width: 96%;
    margin: 0 auto;
}

.text-left {
    text-align: left;
}

.upcase {
    text-transform: uppercase;
}

.let-spacing-5 {
    letter-spacing: -0.3px;
}

.font-size-85pt {
    font-size: 9.5pt;
}

.font-size-9pt {
    font-size: 9pt;
    font-family: "Sanz-Thin", sans-serif;
}

.font-100 {
    font-weight: 100;
}

.font-weight-200 {
    font-weight: 200;
}

.font-300 {
    font-weight: 300;
}
.font-400 {
    font-weight: 500;
}

.product-image-gallery {
    margin-top: 1000px;
    padding-top: 310px;
    width: 800px;
    margin: 0 auto;
    position: relative;
    display: block;
    background: linear-gradient(transparent,  black);
}

.category-image {
    position: fixed;
    overflow: hidden;
    top: 0;
    max-height: 600px;

}

.width-full {
    width: 100%;
    margin: 0 auto;
}

.bname {
    background-clip: text;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    background: white;
   font-family: "Sanz-Reg", sans-serif;
   margin-top: 5px;
}

.bname::after {
    background: linear-gradient(306deg, black, #7b4397, black, #e72f60);

}


.main-product-name {
    font-size: 30px;
    display: inline-block;
    position: relative;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    font-family: "Sanz-Thin", sans-serif;
}

.black-back {
    width: 100%;
    background: black;
}

.gray-back {
    width: 100%;
    background: black;

}



    .category-image {
        position: fixed;
        overflow: hidden;
        top: 50px;
        max-height: 150px;
        width: 100%;
    }

    .product-container {
        display: inline-block;
        position: relative;
        width: 25%;
        vertical-align: top;
        margin: 0 auto;
        margin-top: 0;
        text-align: center;
    }

    .product-image-gallery {
        margin-top: 0px;
        padding-top: 10px;
        margin: 0 auto;
        width: 100%;
        text-align: center;
        position: relative;
        background: linear-gradient(transparent, black, black, black);
        background: rgb(248, 248, 248);
    
    }


    .three {
        width: 30%;
    }

    .two {
        width: 50%
    }
    
    .cart-title {
        z-index: 99999;
        font-weight: 500;
        font-size: 10pt;
        top: 0;
    }

    .category-label {
        background-color: rgb(250, 250, 250);
        font-size: 14px;
        padding: 15px;
        padding-top: 15px;
        text-transform: uppercase;
        color: rgba(0, 0, 0, 0.616);
        color: #7b4397;
        text-align: left;
        position: -webkit-sticky;
        position: sticky;
        top: 50px;
        z-index: 1000;
        letter-spacing: .01em;
        font-family: "Sanz-HeavyCond", sans-serif;
      

    }


    .category-label-2 {
        background-color: rgb(250, 250, 250);
        font-size: 14px;
        padding: 15px;
        padding-top: 15px;
        text-transform: uppercase;
        color: rgba(0, 0, 0, 0.616);
        color: #7b4397;
        text-align: left;
        position: -webkit-sticky;
        position: sticky;
        top: 0px;
        z-index: 1000;
        letter-spacing: .01em;
        font-family: "Sanz-HeavyCond", sans-serif;
    }

    .category-label-3 {
        background-color: rgb(250, 250, 250);
        font-size: 11px;
        padding: 15px;
        padding-top: 15px;
        text-transform: uppercase;
        color: rgba(0, 0, 0, 0.616);
        color: #7b4397;
        text-align: left;
        position: -webkit-sticky;
        position: sticky;
        top: 50px;
        z-index: 1000;
        letter-spacing: .01em;
        font-family: "Sanz-HeavyCond", sans-serif;
    }

    .category-label-3 a {
        font-synthesis: none;
        -moz-font-feature-settings: "kern";
        -webkit-font-smoothing: antialiased;
        color: transparent;
        background: linear-gradient(to right, #eb4191, #7c4a8a);
        background-clip: text;
        -webkit-background-clip: text;
        padding-bottom: 2px;
    }


    /* Hide scrollbar for Chrome, Safari and Opera */
*::-webkit-scrollbar {
    display: none;
  }
  
  /* Hide scrollbar for IE, Edge and Firefox */
  * {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }

    .selected-product {
        padding-top: 10px;
    }



    .category-label-cart {
        background-color: rgb(250, 250, 250);
        font-size: 7pt;
        padding: 10px;
        padding-top: 15px;
        text-transform: uppercase;
        color: rgba(0, 0, 0, 0.616);
        text-align: left;
        position: relative;
        z-index: 10;

    }

    .add-to-cart {
        position: relative;
        margin: 0 auto;
        width: 100%;
        bottom: 0;
        padding-top: 0px;
        padding-bottom: 0px;
        height: 50px;
        border: none;
        border-radius: 50px;
        color: white;
        transition: .7s;
        background: linear-gradient(to right, #7b4397, #e72f60);
        background: transparent;
        box-shadow: rgba(94, 87, 95, 0.2) 0px 7px 29px 0px;
        box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px;
    }

    .add-to-cart::after {
        z-index: -100000;
        border-radius: 50px;
        content: '';
        display: block;    
        position: absolute;
        top: -1px;
        left: -1px;
        width: calc(100% + 2.5px);
        height: calc(100% + 2px);
        transform: translateZ(-1px);
        background: linear-gradient(306deg, black, #7b4397, black, #e72f60);
        background-size: 400% 400%;
     
        -webkit-animation: WelcomePageGradient 4s ease infinite;
        animation: WelcomePageGradient 4s ease infinite;
     }

     .section {
        margin-top: 10px;
        padding-top: 80px;
        height: 85vh;
        display: block;
        position: relative;
        background: transparent;
        text-align: center;
        overflow: hidden;
    }

     img.product-section-image {
        margin: 0 auto;
        position: absolute;
        bottom: -4%;
        left: 50%;
        transform: translate(-50%);
        z-index: 99;
        width: 680px;
     }

     .section-call-to-action {
        width: 80%;
        margin: 0 auto;
        text-align: center;
        margin-bottom: 30px;
        position: absolute;
        top: 44%; left: 50%; transform: translate(-50%, -50%);
        z-index: 999;
        padding: 20px;

     }

     .call-to-action, .call-to-action i {
        color: #b8afba;
        color: rgb(191, 90, 242);
        text-transform: uppercase;
        font-size: 18px;

        margin: 0 auto;
        z-index: 99999;
        font-synthesis: none;
        -moz-font-feature-settings: "kern";
        -webkit-font-smoothing: antialiased;
        color: transparent;
        background: linear-gradient(to bottom right, #eb4191, #a563b7);
        background-clip: text;
        -webkit-background-clip: text;
 
     }

     button.add-to-cart-small {
        position: absolute;
        top: 22vw;
        text-align: center;
        vertical-align: middle;
        right: 0px;
        width: 40px;
        height: 30px;
        background: transparent;
        z-index: 99999;
     }
     

     button.add-to-cart-small::after {
        z-index: -100000;
        border-radius: 50px;
        content: '';
        display: block;    
        position: absolute;
        top: -1px;
        left: -1px;
        width: calc(100% + 2.5px);
        height: calc(100% + 2px);
        transform: translateZ(-1px);
        background: linear-gradient(306deg, black, #7b4397, black,  #e72f60);
        background-size: 400% 400%;
     
        -webkit-animation: WelcomePageGradient 4s ease infinite;
        animation: WelcomePageGradient 4s ease infinite;
     }

     .add-text {
         color: white;
         position: absolute;
         top: 50%; left: 50%;
         transform: translate(-50%, -50%);
         font-size: 9.5pt;
         font-weight: 300;
     }

    .add-to-cart-container {
        position: fixed;
        bottom: 10px;
        width: 40%;
        left: 50%; transform: translate(-50%);
        background-color: transparent;
        z-index: 999;
    }

    .add-to-cart-container5 {
        position: fixed;
        bottom: 10px;
        width: 30%;
        right: 5%; transform: translate(0%);
        background-color: transparent;
        z-index: 999999999999999;
    }




    button {
        border: 1px solid transparent;
        outline: none;
        box-shadow: none;
        width: 90%;
        height: 40px;
        background: linear-gradient(to right, #7b4397, #e72f60);
        background-size: 100% 200%;
        background: transparent;
        background-position: 0 var(--modal-button-gradient-pos);
        color: black;
        margin: 20px;
        font-weight: 300;
        font-size: 8.5pt;
    }

 

    .bname {
        background: linear-gradient(306deg, black, #7b4397, black, #e72f60);
        background-size: 300% 300%;
     
        -webkit-animation: WelcomePageGradient 6s ease infinite;
        animation: WelcomePageGradient 6s ease infinite;
        background-clip: text;
        -webkit-background-clip: text;
        color: transparent;
     
        font-size: 12px;
        font-weight: 500;
    }

    .brand-name {
        font-size: 20px;
        font-weight: 300;
        text-transform: uppercase;
 
        font-family: "Sanz-ThinCond", sans-serif;
    }

    .cost {
        font-size: 18px;
        font-weight: 400;
        margin-top: 10px;
        display: inline-block;
        font-family: "Sanz-Thin", sans-serif;
    }

    .product-desc {
        margin-top: 10px;

        font-family: "Sanz-RegCond", sans-serif;
    }



.mobile-navbar-top {
    height: 50px;
    position: relative;
    background-color: white;
    display: block;
}

.view-by {
    text-transform: uppercase;
    letter-spacing: -0.5px;
    font-size: 9pt;
    text-align: left;
    padding-left: 25px;
    display: inline-block;
    font-weight: 300;
    width: 90%;
}

.selected {
    font-size: 9.5;
}

.space-10 {
    padding-left: 12px;
    padding-bottom: 20px;
    display: none;

}

.product-category {
    font-size: 11pt;
    
    padding: 20px;
}


.width-full {
    width: 100%;
    margin: 0 auto;
}



.c {
    text-align: center;
}


.no-pad {
    padding-bottom: 30px;
}

.trans-back {
    background-color: transparent;
}

.link {
    color: #7b4397;
    font-weight: 400;
}

.pad-20 {
    padding-bottom: 40px;
}

.pad-5 {
    padding-top: 5px;
}

.border-top {
    border-top: 1px solid rgba(0, 0, 0, 0.247);
}

.border-bottom {
    border-bottom: 1px solid rgba(0, 0, 0, 0.247);
}

.light-box-shadow {
    box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px;
}

.pad-top-btm {
    padding-top: 8px;
    padding-bottom: 8px;

}

button.category-label {
    padding-left: 0;
    padding-right: 0;
    width: 90%;

}

.wht {
    color: rgba(255, 255, 255, 0.911);
}

.cost {
    color: rgba(22, 22, 22, 0.911);
}

.name {
    color: rgb(22, 22, 22);
    padding-top: 15px;
}

* {
    -webkit-tap-highlight-color: transparent;
}

.black-back {
    width: 100%;
    background: black;
}

.sanz-black {
    font-family: "Sanz-Black", sans-serif;
}

.sanz-heavy {
    font-family: "Sanz-Heavy", sans-serif;
}

.sanz-reg {
    font-family: "Sanz-Reg", sans-serif;
}

.sanz-thin {
    font-family: "Sanz-Thin", sans-serif;
}

.apple-h2 {
    color: #cecece;
    color: white;
    display: inline-block;
    max-width: unset;
    margin: 0 auto;
    position: relative;
    z-index: 3;
    pointer-events: none;
    font-size: 92px;
    line-height: .9;
    font-weight: 600;
    letter-spacing: -.03em;
    font-synthesis: none;
    -moz-font-feature-settings: "kern";
    -webkit-font-smoothing: antialiased;
    color: transparent;
    background: linear-gradient(to right, #eb4191, #7c4a8a);
    background-clip: text;
    -webkit-background-clip: text;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    background-size: cover;
    text-transform: uppercase;
    background-size: auto, auto;
}

.apple-h3 {
    color: #cecece;
    color: white;
    max-width: 57.6470588235rem;
    margin: 0 auto;
    position: relative;
    z-index: 3;
    pointer-events: none;
    font-size: 85px;
    line-height: .51;
    font-weight: 600;
    letter-spacing: -.03em;
    font-synthesis: none;
    text-transform: uppercase;
    -moz-font-feature-settings: "kern";
    -webkit-font-smoothing: antialiased;
}



.gray-font {
    color: #cecece;
}

.purple-back {
    background: transparent;
    position: absolute;
    top: 56%;
    width: 50%;
    left: 50%; transform: translate(-50%);
    display: block;
    z-index: -10;
    box-shadow: 0px 0px 180px 30px #e43bfa;
    opacity: .44;
}

.pink-back {
    background: transparent;
    position: absolute;
    top: 62%;
    width: 40%;
    left: 50%; transform: translate(-50%);
    display: block;
    z-index: -10;
    box-shadow: 0px 0px 180px 30px #fa3bd1;
    opacity: .44;
}

.purple-back-frontpg {
    background: transparent;
    position: absolute;
    top: 64%;
    width: 50%;
    left: 50%; transform: translate(-50%);
    display: block;
    z-index: 0;
    box-shadow: 0px 0px 180px 30px #e43bfa;
    opacity: .44;
}

.pink-back-frontpg {
    background: transparent;
    position: absolute;
    top: 73%;
    width: 40%;
    left: 50%; transform: translate(-50%);
    display: block;
    z-index: 0;
    box-shadow: 0px 0px 180px 30px #fa3bd1;
    opacity: .44;
}

.inkbrush-image {
   position: absolute;
   margin-top: -15%;
   width: 680px;
   left: 50%; transform: translate(-50%);
    
}

.fade-in-and-out {
    -webkit-animation: fade-in-and-out 4s ease-in-out infinite;
    animation: fade-in-and-out 4s  ease-in-out infinite;
}

.fade-in-and-out2 {
    -webkit-animation: fade-in-and-out2 4s  ease-in-out infinite;
    animation: fade-in-and-out2 4s  ease-in-out infinite;
}

.height-60 {
    height: 60vh;

}

.apple-gray-back {
    background: #161617;
    width: 100%;
    padding: 20px;
    padding-top: 40px;
    padding-bottom: 130px;
    text-align: center;
    font-size: 28px;
    line-height: 1.6em;
    font-family: "Sanz-Thin", sans-serif;
}

.ink-gradient {
    color: transparent;
    background: linear-gradient(to right, #eb4191, #7c4a8a);
    background-clip: text;
    -webkit-background-clip: text;
    font-size: 29px;
    line-height: 1.6em;
    font-family: "Sanz-Thin", sans-serif;
}


.deeper-gray-font {
   color: #b4aab2;
   font-family: "Sanz-HeavyCond", sans-serif;
   line-height: 1.4285914286;
   letter-spacing: -0.016em;
   font-size: 16px;
   font-weight: 400;
}

.sanz-thin {
    font-family: "Sanz-Thin", sans-serif;
}

.inkbrush-text {
    position: absolute;
    left: 50%; transform: translate(-50%);
    width: 100%;
    padding-bottom: 0;
    margin-bottom: 0;
}

.sanz-regitalic {
    font-family: "Sanz-RegItalic", sans-serif;
}

a.purp-btn, a.purp-btn i {
    background: none;
    color: #7b4397;
    margin: 0 auto;
    text-transform: none;
    font-size: 16px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    font-weight: 400;
    border-radius: 0;
    margin-bottom: 20px;
}

button.purp-btn i { 
    font-weight: 400;
}

.no-caps {
    text-transform: none;
}

.abs-right {
    float: right;
    font-size: 16px;
}

.border-top-bottom {
    border-bottom: .5px solid #3a3939;


}

.discover-links {
    position: relative;
    top: 10px;
    text-align: center;
    width: 100%;
    font-family: "Sanz-Reg", sans-serif;
    font-size: 14px;
    line-height: 1.6em;
    margin: 0 auto;
    border-bottom: .5px solid #3a3939;
    padding-bottom: 10px;

}

.discover-link {
    padding: 7px;

}

.opacity-7 {
    opacity: .9;
    -webkit-filter: grayscale(60%) blur(3px);
            filter: grayscale(60%) blur(3px);
    
}

.black-bubble {
    margin-top: 4%;
    width: 500px;
    height: 500px;
    padding: 40px;
    left: 50%;
    transform: translate(-50%);
    background: black;
    border-radius: 20px;
    position: relative;
    display: block;
    background-image: url(https://cosmetinkshop.s3.us-east-2.amazonaws.com/inbrush_work2.PNG);
    background-size: 100% auto;
    background-repeat: no-repeat;
    background-position: left top;
}

.bubble-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(230deg, transparent, transparent, rgba(0, 0, 0, 0.226), #7b43976b);
    z-index: 9;
    border-radius: 20px;
}

.font-14 {
    font-size: 18px;
    line-height: 1.4em;
}

.bottom {
    width: 80%;
    position: absolute;
    bottom: 14%;
    left: 5%;
    text-align: left;
    z-index: 99;
    font-size: 20px;
    font-family: "Sanz-Heavy", sans-serif;
}

.black-bubble img.bubble-image {
   position: relative;
   display: block;
   width: 100%;
}

.white-back {
    background: white;
}

.black-back {background: black}

.line3 {
    padding: 0px;
    position: absolute;
    top: 33%;
    color: #cecece;
    z-index: 99;
    font-size: 24px;
}

.yellow-back-frontpg {
    background: transparent;
    position: absolute;
    top: 68%;
    width: 50%;
    left: 50%; transform: translate(-50%);
    display: block;
    z-index: 0;
    box-shadow: 0px 0px 180px 30px #6a2f8cd1;
    opacity: .44;
}

.white-back-frontpg {
    background: transparent;
    position: absolute;
    top: 72%;
    width: 40%;
    left: 50%; transform: translate(-50%);
    display: block;
    z-index: 0;
    box-shadow: 0px 0px 170px 20px #d7d7d7b3;
    opacity: .34;
}

.gray-back {
    width: 100%;
    background: #f5f5f7;
    color: black;

}

.blk {
    color: black;
}

.name2 {
    font-family: "Sanz-Reg", sans-serif;
    margin-top: 9px;
}
.name2 span {
    font-family: "Sanz-Thin", sans-serif;
}
.cost2 {
    font-family: "Sanz-Reg", sans-serif;
}

.success-page {
    position: fixed;
    z-index: 9999999999999999999999999999;
    height: 100vh;
    width: 100%;
}

.abs-c {
  position: absolute;
  top: 40%; left: 50%;
  transform: translate(-50%, -50%);
}
.add-to-cart-container2 {
    position: fixed;
    bottom: 10px;
    width: 80%;
    left: 50%; transform: translate(-50%);
    background-color: transparent;
    z-index: 99999999999999999999999999999999999999999999999;
}

.shipping-container {
    position: fixed;
    top: 50px;
    width: 40vw;
    
    height: 100vh;
    background: white;
   
    right: 0;
    overflow-y: scroll;
    z-index: 999999999999999999999999;
    transition: .7s;
}

.inside-shipping {
    padding: 20px;
    min-height: 140vh;
}

select {
    font-size: 16px;
    padding: 12px;
    margin-top: 20px;
    background: none;
    padding: 12px;
    width: 100%;
    border-radius: 5px;
    border-width: 1px;
    margin-top: 20px;
    font-size: 16px;
    border: 1px solid rgba(0, 0, 0, 0.15);
}

select#size {
    font-size: 16px;
    padding: 12px;
    margin-top: 0px;
    background: none;
    padding: 13px;
    width: 300px;
    color: white;
    border-radius: 5px;
    border-width: 1px;
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 16px;

    border: 1px solid white;
}

label {
    font-size: 16px;
}

.checkout {
    z-index: 9999999999999999999999999999999999999999999;
    transition: 4s;
}

.saving {
    padding-bottom: 20px;
    font-weight: 500;
    color: #7b4397;
}

.saving-no {
    padding-bottom: 10px;
    font-weight: 500;
    color: red;
}

@media (max-width: 800px) {
    select#size {
        font-size: 16px;
        padding: 12px;
        margin-top: 0px;
        background: none;
        padding: 13px;
        width: 100%;
        color: white;
        border-radius: 5px;
        border-width: 1px;
        margin-top: 10px;
        margin-bottom: 10px;
        font-size: 16px;
    
        border: 1px solid white;
    }

.front-page-vid-container {
    position: relative;
    height: 100%;
    max-height: 100vh;
    overflow: none;
  }

  video.fit-abs {

  }
  
  .front-page-vid-overlay {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(rgba(0, 0, 0, 0.68), transparent, rgba(0, 0, 0, 0.226), transparent);
    background: none;
  }

  * {
    color: black;
    color: rgba(0, 0, 0, 0.616);
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    margin: 0;
}


.entry-text-low {
    position: absolute;
    font-size: 14px;
    line-height: 1.47059;
    font-weight: 300;
    letter-spacing: -0.5px;
    font-family: "SF Pro Text","SF Pro Icons","Helvetica Neue","Helvetica","Arial",sans-serif;
    width: 100%;
    text-align: left;
    top: 70%;
    color: white;
  
    font-size: 12px;
    
    line-height: 1.6;
    font-weight: 200;
    text-align: center;
    letter-spacing: 1.2px;

    width: 100%;
    color: white;
    z-index: 200;

  }

  .top70 {
      top: 70%;

  }
  .black-friday-special {
    font-weight: 900;
    color: white;
    z-index: 200;
    display: block;
    font-size: 28px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: lighter;
    margin: 0 auto;
    position: relative;
    text-align: center;
    width: 100%;
  
    top: 60%;
    line-height: 1.05em;
    padding-top: 5px;
    padding-bottom: 8px;
    -webkit-font-smoothing: antialiased;
    left: 50%; transform: translate(-50%);
    color: white;
    font-weight: 100;
    font-size: 22=4px;
    line-height: 1.47059;
    font-weight: lighter;
    letter-spacing: 0.5px;
    font-family: "SF Pro Text","SF Pro Icons","Helvetica Neue","Helvetica","Arial",sans-serif;
    width: 100%;
 }

 .pad-left-5-p {
     padding-left: 5%;
 }



 .uppercase {
     text-transform: uppercase;
 }

 .inline-block {
    width: 100%;
    display: inline-block;
 }

 .italic-font {
     font-style: italic;
     text-transform: capitalize;
 }


img.landing-page-image {
    position: fixed;
    max-height: 101vh;
    overflow: hidden;
    top: 0;
    left: 0;
    width: 100%;
    z-index: -10;
    opacity: .6;
    background-color: transparent;
}



.front-page-title {
    font-size: 8.8vh;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: lighter;
    margin: 0 auto;
    position: absolute;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    top: 46%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    z-index: 90;
    width: 100%;
    color: white;
    background-color: transparent;
}

.front-page-logo {
    width: 100%;
}

.opacity-overlay {
    z-index: -300;
    background-color: rgb(0, 0, 0);
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 101vh;
}

.mobile-navbar {
    position: fixed;
    bottom: 0em;
    display: block;
    margin: 0 auto;
    margin-top: 2.5em;
    padding-top: 0em;
    padding-top: 0;
    padding-bottom: .6em;
    background: white;
    text-align: center;
    width: 100%;
    z-index: 60;
    border-top: none;
    background-color: white;
}

i {
 background-color: transparent;
 color: black;
 font-weight: inherit;
 font-style: inherit;
}

i.fa-star {
    color: white;
}

.mobile-navbar-link {
    display: inline-block;
    width: 16%;
    padding: 2%;
    padding-top: 1.2em;
    padding-bottom: 1em;

    font-size: 8pt;
    font-weight: 400;
    background-color: rgb(19, 19, 19);
    background-color: transparent;
    margin-left: .3em;
    margin-right: .3em;
    margin: 0 auto;
    border-radius: 7px;
    color: white;
    opacity: 1;
    text-decoration: none;
    vertical-align: top;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.mobile-navbar-link i {
    font-size: 20pt;
    padding: 4px;
    font-weight: 400;
}

.right-link i {
    color: transparent;
    font-size: 15pt;
}

.test-font {
    color: black;
}

.get-started {
    position: absolute;
    top: 50%;
    transform: translate(-50%);
    left: 50%;
    display: block;
    width: 160px;
    height: 52px;
    margin: 20px auto;
    vertical-align: middle;
    font-size: 40px;
    color: white;
    padding: 0px;
    text-align: center;
    text-transform: uppercase;
    font-size: 13px;
    font-weight: 500;
    font-size: 15px;
    -webkit-appearance: none;
    transition: .4s;
    border-image: linear-gradient(to right, #005e7f, #61b6cd);
    border-radius: 50px;
    background-size: 100%;
    background: white;
    border: 2px transparent;
    border-radius: 30px;
    background-origin: border-box;
    background-clip: content-box, border-box;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    z-index: 0;
  }
  
  .get-started::after {
    z-index: -100;
    border-radius: 50px;
    content: '';
    display: block;    
    position: absolute;
    top: -1px;
    left: -1px;
    width: calc(100% + 2.5px);
    height: calc(100% + 2px);
    background: linear-gradient(#61b6cd 35%, #005e7f 45%);
    background: linear-gradient(to right, #7b4397, #e72f60);
    background-size: 100% 200%;
    background-position: 0 var(--modal-button-gradient-pos);
    transform: translateZ(-1px);
  }

  .entry-btn {
    position: absolute;
    top: 46%;
    transform: translate(0, -50%);
    display: block;
    width: 100%;
    height: 52px;
    margin: 15px auto;
    vertical-align: middle;
    font-size: 40px;
    color: white;
    padding: 0px;
    text-align: center;
    text-transform: uppercase;

    font-size: 8.5pt;
    font-weight: 400;
    font-family: Helvetica_Regular,Arial,sans-serif;
    -webkit-appearance: none;
    transition: .4s;
    border-radius: 50px;
    background-size: 100%;
    background: transparent;
    border: 2px transparent;
    border-radius: 30px;
    background-origin: border-box;
    background-clip: content-box, border-box;
    z-index: 0;
    text-decoration: underline;
  }
  
  span.btn-text {
    position: absolute;
    width: 100%;
    left: 0;
    top: 51%;
    transform: translate(0, -50%);
    color: white;
  }
  
  a.get-started {
   font-weight: 600;
   font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
   
  }

  .menu {
        position: fixed;
        display: block;
        color: rgb(255, 255, 255);
        width: 70%;
        border: none;
        top: 0em;
        opacity: 1;
        background-color: white;
        background-color: transparent;
        box-shadow: none;
        text-align: left;
        right: 0;
        height: 100vh;
        z-index: 9999000;
        transition: .4s cubic-bezier(0.6, -0.28, 0.735, 0.045);
  }

  .page {
    position: relative;
    padding: 0px;
    padding-top: 47px;
    margin: 0;
    padding-left: 10px;
    padding-right: 10px;
    background-color: white;
    background-color: transparent;
}

.full-products {
    margin-top: 50px;
}

.full-background {
    background-color: white;
    background-color: transparent;
    z-index: -100;
    top: 0;
    left: 0;
    right: 0;
    margin: 0;
    min-height: 100vh;
}

.artist-page-title {
    font-size: 14pt;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: lighter;
    padding: .2em;
    padding-left: 0;
    border-bottom: 1px solid #3a3939;
    color: white;
    vertical-align: middle;
    width: 100%;
 }

 a.read-more-2 {
    font-weight: 400;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 9pt;
    color: #666;
     padding: 0;
    display: inline-block;
    z-index: 1;
 }
 
 div.read-more-2 {
     font-weight: 400;
     font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
     font-size: 11pt;
     color: #666;
      padding: 0;
      margin-top: -3.2em;
     text-align: right;
     z-index: 1;
  }

  .PMU-detail-page-dropped {
  
    color: white;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 11pt;
    font-weight: 300;
    line-height: 1.4em;
    max-width: 100%;
 }

 .PMU-detail-hidden {
     color: white;
     font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
     font-size: 11pt;
     line-height: 1.4em; 
     font-weight: 300;
     height: 2.8em;
     overflow: hidden;
     text-overflow: ellipsis;
     max-width: 100%;
 }

 a.read-less {
    width: 0;
    display: none;
    color: transparent;
 }

 .schedule-btn {
    position: relative;
    display: block;
    width: 88%;
    padding: 20px;
    margin: 0px auto;
    margin-top: 30px;
    vertical-align: middle;
    font-size: 40px;
    color: white;
    text-align: center;
    text-transform: uppercase;
    font-size: 13px;
    -webkit-appearance: none;
    transition: .4s;
      border-radius: 50px;
       border-radius: 30px;
      background-size: 100%;
      background: transparent;
      border: 2px transparent;
      background-origin: border-box;
      background-clip: content-box, border-box;
      z-index: 1;
      font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  }

.schedule-btn::after {
    z-index: -1;
    border-radius: 50px;
    content: '';
    display: block;    
    position: absolute;
    top: -1px;
    left: -1px;
    width: calc(100% + 2.5px);
    height: calc(100% + 2px);
    background: linear-gradient(to right, #7b4397, #e72f60);
    background-size: 100% 200%;
    background-position: 0 var(--modal-button-gradient-pos);
    transform: translateZ(-1px);
}

/* Schedule */
.schedule-open {
    position: fixed;
    top: 0;
    bottom: 0;
    background: linear-gradient(to bottom right, #7b4397, #e72f60);
    height: 100vh;
    width: 100%;
    text-align: center;
    z-index: 999999999999999999999999999999;
}

.schedule-inside {
    position: absolute;
    top: 70px;
    left: 50%;
    transform: translate(-50%);
    width: 90%;
    margin: 0 auto;
}

iframe.schedule {
    position: relative;
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: 0px;
    height: 75vh;
    background-color: transparent;
    scrollbar-width: 0px;
    scrollbar-color: transparent;
    -webkit-scrollbar-color: transparent;
}

.schedule-close {
    position: absolute;
    top: 20px;
    font-size: 11pt;
    padding: 5px;
    text-transform: uppercase;
    color: white;
    left: 20px;
    text-align: left;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: 500;
    width: 100%;
    background-color: transparent;
}

.schedule-close:hover {
    cursor: pointer;
}

.schedule-close i {
    font-size: 11pt;
    font-weight: inherit;
}

.ysl {
    font-family: Helvetica_Regular,Arial,sans-serif;
    font-weight: 550;
    text-transform: uppercase;
    font-size: 11pt;
    -webkit-font-smoothing: antialiased;
}

img.match-height {
    z-index: -1;
    opacity: 1;
    max-height: unset;
    position: absolute;
    max-height: 93vh;
    height: 100%;
    max-width: unset;
    width: auto;
    top: 54px;
    left: 50%;
    transform: translate(-50%);
    overflow: hidden;
    background-color: transparent;

}

.main-page {
    position: relative;
    width: 100%;
    overflow: hidden;
    height: 100vh;
    padding-bottom: 100px;
    background-color: transparent;

}

.blk {
    color: black;
}

.blk i {
    color: black;
}

img {
  max-width: 100%;
}
.width-100 {
    width: 100%;
    display: block;
    margin: 0 auto;
    text-align: center;
}

img.width-100 {
    width: 100%;
}

.width-90 {
    width: 97%;

}

.width-full {
    width: 100%;
    height: auto;
    opacity: 1;
    text-align: center;
    background-color: linear-gradient(black, white, black);
    background-color: white;
}

.abs {
    position: absolute;
    top: 50%; left: 50%;
    transform: translate(-50%, -50%);
}



.overlay {
    position: absolute;
    z-index: 999;
    background-color: linear-gradient(to right, #7b4397, #e72f60);
}
 


.product-container {
    display: inline-block;
    position: relative;
    vertical-align: top;
    width: 25%;
    margin-top: 5%;
    height: 300px;
}

/* CART */
.cart-container {
    position: fixed;
    display: block;
    top: 50px;
    height: 100vh;
    right: unset;
    background: white;
    width: 100%;
    z-index: 9;
    transition: .4s;
    overflow-y: scroll;
    z-index: 999;
    text-align: center;
}


.float-left {
    position: absolute;
    left: 15px;
}

.cart-container.menu-button {
    position: fixed;
}

.menu-button-cart {
    position: fixed;
    z-index: 9999999999999999999999999;
    display: block;
    left: 10px;
    background: transparent;
}

.cart-scroll {
    position: relative;
    min-height: 120vh;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    overflow: hidden;
    overflow-y: scroll;
    padding-bottom: 30vh;
    padding-top: 0px;
    width: 96%;
    margin: 0 auto;
}


.cart-item {
    display: block;
    margin-top: 5px;
    width: 100%;
    background-color: rgb(248, 248, 248);
    text-align: center;
}

.cart-item-name {
    display: block;
    text-align: left;
    margin-left: 12%;
    font-weight: 500;
 
}
.cart-image {
    height: 100px;
    text-align: left;
    display: inline-block;
}

span.third {
    width: 30%;
    display: inline-block;
    text-align: center;
    margin: 0 auto;
    vertical-align: middle;
    font-size: 8pt;
    text-transform: uppercase;
    color: rgba(0, 0, 0, 0.616);
    padding-top: 10px;
    padding-bottom: 10px;
}
.left {
    text-align: left;
}

.right {
    text-align: right;
}

img.cart-image {
    text-align: left;
}

.product-image-box {
    display: block;
    height: 48vw;
    width: 48vw;
    margin: 0 auto;
    overflow: hidden;
}



img.product {
    height: 100%;
    width: auto;

    margin: 0 auto;
    text-align: center;
    position: relative;
    display: block;
}


span.quantity-btn {
    position: relative;
    width: 25%;
    padding-top: 8px;
    padding-bottom: 8px;
    display: inline-block;
    text-align: center;
    margin: 0 auto;
    border: 1px solid rgba(0, 0, 0, 0.137);
    background-color: white;
}

span.num {
    border: none;
    
}



button {
    display: relative;
}

.shop-main {
    position: fixed;
    top: 0; left: 0; right: 0; bottom: 0;
    height: 100vh;
    overflow-y: scroll;
  
}

.shop {
    position: relative;
    background-color: rgb(248, 248, 248);
    text-align: center;
    position: relative;
    display: block;
    color: black;
    padding-bottom: 100px;
    width: 100%;
}

.call-to-action-text {
    position: relative;
  
    padding: 10px;
    width: 50%;
    margin: 20px auto;
    font-weight: lighter;
    color: #7b4397;
    
}

.product-name {
    padding-top: 4px;
    padding-bottom: 80px;
    text-align: left;
    width: 96%;
    margin: 0 auto;
}

.text-left {
    text-align: left;
}

.upcase {
    text-transform: uppercase;
}

.let-spacing-5 {
    letter-spacing: -0.3px;
}

.font-size-85pt {
    font-size: 9.5pt;
}

.font-size-9pt {
    font-size: 9pt;
    font-family: "Sanz-Thin", sans-serif;
}

.font-100 {
    font-weight: 100;
}

.font-weight-200 {
    font-weight: 200;
}

.font-300 {
    font-weight: 300;
}
.font-400 {
    font-weight: 500;
}

.product-image-gallery {
    margin-top: 1000px;
    padding-top: 310px;
    width: 800px;
    margin: 0 auto;
    position: relative;
    display: block;
    background: linear-gradient(transparent,  black);
}

.category-image {
    position: fixed;
    overflow: hidden;
    top: 0;
    max-height: 600px;

}

.width-full {
    width: 100%;
    margin: 0 auto;
}

.bname {
    background-clip: text;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    background: white;
   font-family: "Sanz-Reg", sans-serif;
   margin-top: 5px;
}

.bname::after {
    background: linear-gradient(306deg, black, #7b4397, black, #e72f60);

}


.main-product-name {
    font-size: 30px;
    display: inline-block;
    position: relative;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    font-family: "Sanz-Thin", sans-serif;
}

.black-back {
    width: 100%;
    background: black;
}

.gray-back {
    width: 100%;
    background: black;

}



    .category-image {
        position: fixed;
        overflow: hidden;
        top: 50px;
        max-height: 150px;
        width: 100%;
    }

    .product-container {
        display: inline-block;
        position: relative;
        width: 50%;
        vertical-align: top;
        margin: 0 auto;
        margin-top: 0;
        text-align: center;
    }

    .product-image-gallery {
        margin-top: 0px;
        padding-top: 10px;
        margin: 0 auto;
        width: 100%;
        text-align: center;
        position: relative;
        background: linear-gradient(transparent, black, black, black);
        background: rgb(248, 248, 248);
    
    }


    .three {
        width: 30%;
    }

    .two {
        width: 50%
    }
    
    .cart-title {
        z-index: 99999;
        font-weight: 500;
        font-size: 10pt;
        top: 0;
    }

    .category-label {
        background-color: rgb(250, 250, 250);
        font-size: 14px;
        padding: 15px;
        padding-top: 15px;
        text-transform: uppercase;
        color: rgba(0, 0, 0, 0.616);
        color: #7b4397;
        text-align: left;
        position: -webkit-sticky;
        position: sticky;
        top: 50px;
        z-index: 1000;
        letter-spacing: .01em;
        font-family: "Sanz-HeavyCond", sans-serif;
      

    }


    .category-label-2 {
        background-color: rgb(250, 250, 250);
        font-size: 14px;
        padding: 15px;
        padding-top: 15px;
        text-transform: uppercase;
        color: rgba(0, 0, 0, 0.616);
        color: #7b4397;
        text-align: left;
        position: -webkit-sticky;
        position: sticky;
        top: 0px;
        z-index: 1000;
        letter-spacing: .01em;
        font-family: "Sanz-HeavyCond", sans-serif;
    }

    .category-label-2:hover {
        cursor: pointer;
    }

    div.cart-item-name.link:hover {
        cursor: pointer;
    }

    .category-label-3 {
        background-color: rgb(250, 250, 250);
        font-size: 11px;
        padding: 15px;
        padding-top: 15px;
        text-transform: uppercase;
        color: rgba(0, 0, 0, 0.616);
        color: #7b4397;
        text-align: left;
        position: -webkit-sticky;
        position: sticky;
        top: 50px;
        z-index: 1000;
        letter-spacing: .01em;
        font-family: "Sanz-HeavyCond", sans-serif;
    }

    .category-label-3 a {
        font-synthesis: none;
        -moz-font-feature-settings: "kern";
        -webkit-font-smoothing: antialiased;
        color: transparent;
        background: linear-gradient(to right, #eb4191, #7c4a8a);
        background-clip: text;
        -webkit-background-clip: text;
        padding-bottom: 2px;
    }


    /* Hide scrollbar for Chrome, Safari and Opera */
*::-webkit-scrollbar {
    display: none;
  }
  
  /* Hide scrollbar for IE, Edge and Firefox */
  * {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }

    .selected-product {
        padding-top: 10px;
    }



    .category-label-cart {
        background-color: rgb(250, 250, 250);
        font-size: 7pt;
        padding: 10px;
        padding-top: 15px;
        text-transform: uppercase;
        color: rgba(0, 0, 0, 0.616);
        text-align: left;
        position: relative;
        z-index: 10;

    }

    .add-to-cart {
        position: relative;
        margin: 0 auto;
        width: 100%;
        bottom: 0;
        padding-top: 0px;
        padding-bottom: 0px;
        height: 50px;
        border: none;
        border-radius: 50px;
        color: white;
        transition: .7s;
        background: linear-gradient(to right, #7b4397, #e72f60);
        background: transparent;
        box-shadow: rgba(94, 87, 95, 0.2) 0px 7px 29px 0px;
        box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px;
    }

    .add-to-cart::after {
        z-index: -100000;
        border-radius: 50px;
        content: '';
        display: block;    
        position: absolute;
        top: -1px;
        left: -1px;
        width: calc(100% + 2.5px);
        height: calc(100% + 2px);
        transform: translateZ(-1px);
        background: linear-gradient(306deg, black, #7b4397, black, #e72f60);
        background-size: 400% 400%;
     
        -webkit-animation: WelcomePageGradient 4s ease infinite;
        animation: WelcomePageGradient 4s ease infinite;
     }

     .section {
        margin-top: 10px;
        padding-top: 30px;
        height: 60vh;
        position: relative;
        display: block;
        background: transparent;
        text-align: center;
        overflow: hidden;
    }

     img.product-section-image {
        margin: 0 auto;
        position: absolute;
        bottom: -4%;
        left: 0;
        transform: unset;
        z-index: 99;
        width: 100%;
     }

     .section-call-to-action {
        width: 80%;
        margin: 0 auto;
        text-align: center;
        margin-bottom: 30px;
        position: absolute;
        top: 44%; left: 50%; transform: translate(-50%, -50%);
        z-index: 999;
        padding: 20px;

     }

     .call-to-action, .call-to-action i {
        color: #b8afba;
        color: rgb(191, 90, 242);
        text-transform: uppercase;
        font-size: 9.5pt;

        margin: 0 auto;
        z-index: 99999;
        font-synthesis: none;
        -moz-font-feature-settings: "kern";
        -webkit-font-smoothing: antialiased;
        color: transparent;
        background: linear-gradient(to bottom right, #eb4191, #a563b7);
        background-clip: text;
        -webkit-background-clip: text;
 
     }

     button.add-to-cart-small {
        position: absolute;
        top: 45vw;
        text-align: center;
        vertical-align: middle;
        right: 0px;
        width: 40px;
        height: 30px;
        background: transparent;
        z-index: 99999;
     }
     

     button.add-to-cart-small::after {
        z-index: -100000;
        border-radius: 50px;
        content: '';
        display: block;    
        position: absolute;
        top: -1px;
        left: -1px;
        width: calc(100% + 2.5px);
        height: calc(100% + 2px);
        transform: translateZ(-1px);
        background: linear-gradient(306deg, black, #7b4397, black,  #e72f60);
        background-size: 400% 400%;
     
        -webkit-animation: WelcomePageGradient 4s ease infinite;
        animation: WelcomePageGradient 4s ease infinite;
     }

     .add-text {
         color: white;
         position: absolute;
         top: 50%; left: 50%;
         transform: translate(-50%, -50%);
         font-size: 9.5pt;
         font-weight: 300;
     }

    .add-to-cart-container {
        position: fixed;
        bottom: 10px;
        width: 80%;
        left: 50%; transform: translate(-50%);
        background-color: transparent;
        z-index: 999;
    }

    .add-to-cart-container5 {
        position: fixed;
        bottom: 10px;
        width: 80%;
        right: unset;
        left: 50%; transform: translate(-50%);
        background-color: transparent;
        z-index: 999999999999999;
    }




    button {
        border: 1px solid transparent;
        outline: none;
        box-shadow: none;
        width: 90%;
        height: 40px;
        background: linear-gradient(to right, #7b4397, #e72f60);
        background-size: 100% 200%;
        background: transparent;
        background-position: 0 var(--modal-button-gradient-pos);
        color: black;
        margin: 20px;
        font-weight: 300;
        font-size: 8.5pt;
    }

 

    .bname {
        background: linear-gradient(306deg, black, #7b4397, black, #e72f60);
        background-size: 300% 300%;
     
        -webkit-animation: WelcomePageGradient 6s ease infinite;
        animation: WelcomePageGradient 6s ease infinite;
        background-clip: text;
        -webkit-background-clip: text;
        color: transparent;
     
        font-size: 12px;
        font-weight: 500;
    }

    .brand-name {
        font-size: 20px;
        font-weight: 300;
        text-transform: uppercase;
 
        font-family: "Sanz-ThinCond", sans-serif;
    }

    .cost {
        font-size: 18px;
        font-weight: 400;
        margin-top: 10px;
        display: inline-block;
        font-family: "Sanz-Thin", sans-serif;
    }

    .product-desc {
        margin-top: 10px;

        font-family: "Sanz-RegCond", sans-serif;
    }



.mobile-navbar-top {
    height: 50px;
    position: relative;
    background-color: white;
    display: block;
}

.view-by {
    text-transform: uppercase;
    letter-spacing: -0.5px;
    font-size: 9pt;
    text-align: left;
    padding-left: 25px;
    display: inline-block;
    font-weight: 300;
    width: 90%;
}

.selected {
    font-size: 9.5;
}

.space-10 {
    padding-left: 12px;
    padding-bottom: 20px;
    display: none;

}

.product-category {
    font-size: 11pt;
    
    padding: 20px;
}


.width-full {
    width: 100%;
    margin: 0 auto;
}



.c {
    text-align: center;
}


.no-pad {
    padding-bottom: 30px;
}

.trans-back {
    background-color: transparent;
}

.link {
    color: #7b4397;
    font-weight: 400;
}

.pad-20 {
    padding-bottom: 40px;
}

.pad-5 {
    padding-top: 5px;
}

.border-top {
    border-top: 1px solid rgba(0, 0, 0, 0.247);
}

.border-bottom {
    border-bottom: 1px solid rgba(0, 0, 0, 0.247);
}

.light-box-shadow {
    box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px;
}

.pad-top-btm {
    padding-top: 8px;
    padding-bottom: 8px;

}

button.category-label {
    padding-left: 0;
    padding-right: 0;
    width: 90%;

}

.wht {
    color: rgba(255, 255, 255, 0.911);
}

.cost {
    color: rgba(22, 22, 22, 0.911);
}

.name {
    color: rgb(22, 22, 22);
    padding-top: 15px;
}

* {
    -webkit-tap-highlight-color: transparent;
}

.black-back {
    width: 100%;
    background: black;
}

.sanz-black {
    font-family: "Sanz-Black", sans-serif;
}

.sanz-heavy {
    font-family: "Sanz-Heavy", sans-serif;
}

.sanz-reg {
    font-family: "Sanz-Reg", sans-serif;
}

.sanz-thin {
    font-family: "Sanz-Thin", sans-serif;
}

.apple-h2 {
    color: #cecece;
    color: white;
    display: inline-block;
    max-width: unset;
    margin: 0 auto;
    position: relative;
    z-index: 3;
    pointer-events: none;
    font-size: 62px;
    line-height: .9;
    font-weight: 600;
    letter-spacing: -.03em;
    font-synthesis: none;
    -moz-font-feature-settings: "kern";
    -webkit-font-smoothing: antialiased;
    color: transparent;
    background: linear-gradient(to right, #eb4191, #7c4a8a);
    background-clip: text;
    -webkit-background-clip: text;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    background-size: cover;
    text-transform: uppercase;
    background-size: auto, auto;
}

.apple-h3 {
    color: #cecece;
    color: white;
    max-width: 57.6470588235rem;
    margin: 0 auto;
    position: relative;
    z-index: 3;
    pointer-events: none;
    font-size: 55px;
    line-height: .51;
    font-weight: 600;
    letter-spacing: -.03em;
    font-synthesis: none;
    text-transform: uppercase;
    -moz-font-feature-settings: "kern";
    -webkit-font-smoothing: antialiased;
}



.gray-font {
    color: #cecece;
}

.purple-back {
    background: transparent;
    position: absolute;
    top: 56%;
    width: 60%;
    left: 50%; transform: translate(-50%);
    display: block;
    z-index: -10;
    box-shadow: 0px 0px 180px 30px #e43bfa;
    opacity: .44;
}

.pink-back {
    background: transparent;
    position: absolute;
    top: 62%;
    width: 60%;
    left: 50%; transform: translate(-50%);
    display: block;
    z-index: -10;
    box-shadow: 0px 0px 180px 30px #fa3bd1;
    opacity: .44;
}

.purple-back-frontpg {
    background: transparent;
    position: absolute;
    top: 56%;
    width: 60%;
    left: 50%; transform: translate(-50%);
    display: block;
    z-index: 0;
    box-shadow: 0px 0px 180px 30px #e43bfa;
    opacity: .44;
}

.pink-back-frontpg {
    background: transparent;
    position: absolute;
    top: 62%;
    width: 60%;
    left: 50%; transform: translate(-50%);
    display: block;
    z-index: 0;
    box-shadow: 0px 0px 180px 30px #fa3bd1;
    opacity: .44;
}

.inkbrush-image {
    width: unset;
    left: unset; transform: unset;
    margin-top: -15%;
    position: relative;
}

.fade-in-and-out {
    -webkit-animation: fade-in-and-out 4s ease-in-out infinite;
    animation: fade-in-and-out 4s  ease-in-out infinite;
}

.fade-in-and-out2 {
    -webkit-animation: fade-in-and-out2 4s  ease-in-out infinite;
    animation: fade-in-and-out2 4s  ease-in-out infinite;
}

.height-60 {
    height: 60vh;

}

.apple-gray-back {
    background: #161617;
    width: 100%;
    padding: 20px;
    padding-top: 40px;
    padding-bottom: 140px;
    text-align: center;
    font-size: 28px;
    line-height: 1.6em;
    font-family: "Sanz-Thin", sans-serif;
}

.ink-gradient {
    color: transparent;
    background: linear-gradient(to right, #eb4191, #7c4a8a);
    background-clip: text;
    -webkit-background-clip: text;
    font-size: 29px;
    line-height: 1.6em;
    font-family: "Sanz-Thin", sans-serif;
}


.deeper-gray-font {
   color: #86868b;
   font-family: "Sanz-HeavyCond", sans-serif;
   line-height: 1.4285914286;
   letter-spacing: -0.016em;
   font-size: 16px;
   font-weight: 400;
}

.sanz-thin {
    font-family: "Sanz-Thin", sans-serif;
}

.inkbrush-text {
    position: absolute;
    left: 50%; transform: translate(-50%);
    width: 100%;
    padding-bottom: 0;
    margin-bottom: 0;
}

.sanz-regitalic {
    font-family: "Sanz-RegItalic", sans-serif;
}

a.purp-btn, a.purp-btn i {
    background: none;
    color: #7b4397;
    margin: 0 auto;
    text-transform: none;
    font-size: 16px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    font-weight: 400;
    border-radius: 0;
    margin-bottom: 20px;
}

button.purp-btn i { 
    font-weight: 400;
}

.no-caps {
    text-transform: none;
}

.abs-right {
    float: right;
    font-size: 16px;
}

.border-top-bottom {
    border-bottom: .5px solid #3a3939;


}

.discover-links {
    position: relative;
    top: 10px;
    text-align: center;
    width: 100%;
    font-family: "Sanz-Reg", sans-serif;
    font-size: 14px;
    line-height: 1.6em;
    margin: 0 auto;
    border-bottom: .5px solid #3a3939;
    padding-bottom: 10px;

}

.discover-link {
    padding: 7px;

}

.opacity-7 {
    opacity: .9;
    -webkit-filter: grayscale(60%) blur(3px);
            filter: grayscale(60%) blur(3px);
    
}

.black-bubble {
    margin-top: 10%;
    width: 100%;
    height: 320px;
    padding: 40px;
    left: unset;
    transform: unset;
    background: black;
    border-radius: 20px;
    position: relative;
    display: block;
    background-image: url(https://cosmetinkshop.s3.us-east-2.amazonaws.com/inbrush_work2.PNG);
    background-size: 100% auto;
    background-repeat: no-repeat;
    background-position: left top;
}

.bubble-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(230deg, transparent, transparent, rgba(0, 0, 0, 0.226), #7b43976b);
    z-index: 9;
    border-radius: 20px;
}

.font-14 {
    font-size: 18px;
    line-height: 1.4em;
}

.bottom {
    width: 80%;
    position: absolute;
    bottom: 14%;
    left: 5%;
    text-align: left;
    z-index: 99;
    font-size: 20px;
    font-family: "Sanz-Heavy", sans-serif;
}

.black-bubble img.bubble-image {
   position: relative;
   display: block;
   width: 100%;
}

.white-back {
    background: white;
}

.black-back {background: black}

.line3 {
    padding: 0px;
    position: absolute;
    top: 33%;
    color: #cecece;
    z-index: 99;
    font-size: 16px;
}

.yellow-back-frontpg {
    background: transparent;
    position: absolute;
    top: 68%;
    width: 60%;
    left: 50%; transform: translate(-50%);
    display: block;
    z-index: 0;
    box-shadow: 0px 0px 180px 30px #6a2f8cd1;
    opacity: .44;
}

.white-back-frontpg {
    background: transparent;
    position: absolute;
    top: 62%;
    width: 60%;
    left: 50%; transform: translate(-50%);
    display: block;
    z-index: 0;
    box-shadow: 0px 0px 180px 30px #d7d7d7b3;
    opacity: .34;
}

.gray-back {
    width: 100%;
    background: #f5f5f7;
    color: black;

}

.blk {
    color: black;
}

.name2 {
    font-family: "Sanz-Reg", sans-serif;
    margin-top: 9px;
}
.name2 span {
    font-family: "Sanz-Thin", sans-serif;
}
.cost2 {
    font-family: "Sanz-Reg", sans-serif;
}

.success-page {
    position: fixed;
    z-index: 9999999999999999999999999999;
    height: 100vh;
    width: 100%;
}

.abs-c {
  position: absolute;
  top: 40%; left: 50%;
  transform: translate(-50%, -50%);
}
.add-to-cart-container2 {
    position: fixed;
    bottom: 10px;
    width: 80%;
    left: 50%; transform: translate(-50%);
    background-color: transparent;
    z-index: 99999999999999999999999999999999999999999999999;
}

.shipping-container {
    position: fixed;
    top: 50px;
    width: 100vw;
    right: unset;
    height: 100vh;
    background: white;
    left: 0%;
    overflow-y: scroll;
    z-index: 999999999999999999999999;
    transition: .7s;
}

.inside-shipping {
    padding: 20px;
    min-height: 140vh;
}

select {
    font-size: 16px;
    padding: 12px;
    margin-top: 20px;
    background: none;
    padding: 12px;
    width: 100%;
    border-radius: 5px;
    border-width: 1px;
    margin-top: 20px;
    font-size: 16px;
    border: 1px solid rgba(0, 0, 0, 0.15);
}

label {
    font-size: 16px;
}

.checkout {
    z-index: 9999999999999999999999999999999999999999999;
    transition: 4s;
}

}

.email-test {
    font-style: italic;
    padding-top: 7px;
    color: red;
}

.detail-box {
    text-align: left;
}

.box-desc {
    font-size: 16px;
    line-height: 1.4em;
    font-family: "Sanz-Reg", sans-serif;
}

ul.wht {
    margin: 0;
    padding-left: 0;
    font-family: "Sanz-Reg", sans-serif;
}

.bold.wht {
    margin: 0;
    padding-left: 0;
    font-family: "Sanz-Heavy", sans-serif;
}

.footer {
    background: #e7e2e9;
    color: black;
    padding: 20px;
    font-size: 11px;
    font-synthesis: none;
    margin-top: 10px;
}

a.mailto {
    font-synthesis: none;
    -moz-font-feature-settings: "kern";
    -webkit-font-smoothing: antialiased;
    color: transparent;
    background: linear-gradient(to right, #eb4191, #7c4a8a);
    background-clip: text;
    -webkit-background-clip: text;
}

.red {color: rgba(255, 255, 255, 0.969)}
.fade-in {
	-webkit-animation: fade-in 1.2s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	        animation: fade-in 1.2s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

@-webkit-keyframes WelcomePageGradient {
  0%{background-position:0% 47%}
  50%{background-position:100% 54%}
  100%{background-position:0% 47%}
}
@keyframes WelcomePageGradient {
  0%{background-position:0% 47%}
  50%{background-position:100% 54%}
  100%{background-position:0% 47%}
}

.slide-in-right {
	-webkit-animation: slide-in-right .35s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-in-right .35s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

.slide-in-blurred-top {
	-webkit-animation: slide-in-blurred-top 0.6s cubic-bezier(0.230, 1.000, 0.320, 1.000) both;
	        animation: slide-in-blurred-top 0.6s cubic-bezier(0.230, 1.000, 0.320, 1.000) both;
}

.slide-out-blurred-top {
	-webkit-animation: slide-out-blurred-top 0.45s cubic-bezier(0.755, 0.050, 0.855, 0.060) both;
	        animation: slide-out-blurred-top 0.45s cubic-bezier(0.755, 0.050, 0.855, 0.060) both;
}

.fade-in {
	-webkit-animation: fade-in 1.2s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	        animation: fade-in 1.2s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

.slide-out-right {
	-webkit-animation: slide-out-right 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
	        animation: slide-out-right 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
}

.fade-out {
	-webkit-animation: fade-out 1s ease-out both;
	        animation: fade-out 1s ease-out both;
}

.slide-in-bottom {
	-webkit-animation: slide-in-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-in-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

 @-webkit-keyframes slide-in-right {
    0% {
      transform: translateX(1000px);
      opacity: 0;
    }
    100% {
      transform: translateX(0);
      opacity: 1;
    }
  }
  @keyframes slide-in-right {
    0% {
      transform: translateX(1000px);
      opacity: 0;
    }
    100% {
      transform: translateX(0);
      opacity: 1;
    }
  }

  /* ----------------------------------------------
 * Generated by Animista on 2023-8-14 22:28:54
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-in-top
 * ----------------------------------------
 */
@-webkit-keyframes slide-in-top {
  0% {
    transform: translateY(-100px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes slide-in-top {
  0% {
    transform: translateY(-100px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}


  .slide-in-top {
    -webkit-animation: slide-in-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
            animation: slide-in-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  }
  
  .text-pop-up-top {
    -webkit-animation: text-pop-up-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
            animation: text-pop-up-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  }

  /* ----------------------------------------------
 * Generated by Animista on 2023-8-14 22:36:59
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation text-pop-up-top
 * ----------------------------------------
 */
@-webkit-keyframes text-pop-up-top {
  0% {
    transform: translateY(0);
    transform-origin: 50% 50%;
    text-shadow: none;
  }
  100% {
    transform: translateY(-50px);
    transform-origin: 50% 50%;
    text-shadow: 0 1px 0 #cccccc, 0 2px 0 #cccccc, 0 3px 0 #cccccc, 0 4px 0 #cccccc, 0 5px 0 #cccccc, 0 6px 0 #cccccc, 0 7px 0 #cccccc, 0 8px 0 #cccccc, 0 9px 0 #cccccc, 0 50px 30px rgba(0, 0, 0, 0.3);
  }
}
@keyframes text-pop-up-top {
  0% {
    transform: translateY(0);
    transform-origin: 50% 50%;
    text-shadow: none;
  }
  100% {
    transform: translateY(-50px);
    transform-origin: 50% 50%;
    text-shadow: 0 1px 0 #cccccc, 0 2px 0 #cccccc, 0 3px 0 #cccccc, 0 4px 0 #cccccc, 0 5px 0 #cccccc, 0 6px 0 #cccccc, 0 7px 0 #cccccc, 0 8px 0 #cccccc, 0 9px 0 #cccccc, 0 50px 30px rgba(0, 0, 0, 0.3);
  }
}

.tracking-in-expand {
	-webkit-animation: tracking-in-expand 1.4s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
	        animation: tracking-in-expand 1.4s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2023-8-14 22:38:20
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation tracking-in-expand
 * ----------------------------------------
 */
 @-webkit-keyframes tracking-in-expand {
  0% {
    letter-spacing: -0.5em;
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
}
@keyframes tracking-in-expand {
  0% {
    letter-spacing: -0.5em;
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
}





 @-webkit-keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

@-webkit-keyframes slide-in-blurred-top {
    0% {
      transform: translateY(-1000px) scaleY(2.5) scaleX(0.2);
      transform-origin: 50% 0%;
      -webkit-filter: blur(40px);
              filter: blur(40px);
      opacity: 0;
    }
    100% {
      transform: translateY(0) scaleY(1) scaleX(1);
      transform-origin: 50% 50%;
      -webkit-filter: blur(0);
              filter: blur(0);
      opacity: 1;
    }
  }
  @keyframes slide-in-blurred-top {
    0% {
      transform: translateY(-1000px) scaleY(2.5) scaleX(0.2);
      transform-origin: 50% 0%;
      -webkit-filter: blur(40px);
              filter: blur(40px);
      opacity: 0;
    }
    100% {
      transform: translateY(0) scaleY(1) scaleX(1);
      transform-origin: 50% 50%;
      -webkit-filter: blur(0);
              filter: blur(0);
      opacity: 1;
    }
  }

@-webkit-keyframes slide-out-blurred-top {
    0% {
      transform: translateY(0) scaleY(1) scaleX(1);
      transform-origin: 50% 0%;
      -webkit-filter: blur(0);
              filter: blur(0);
      opacity: 1;
    }
    100% {
      transform: translateY(-1000px) scaleY(1) scaleX(0.2);
      transform-origin: 50% 0%;
      -webkit-filter: blur(40px);
              filter: blur(40px);
      opacity: 0;
    }
  }
  @keyframes slide-out-blurred-top {
    0% {
      transform: translateY(0) scaleY(1) scaleX(1);
      transform-origin: 50% 0%;
      -webkit-filter: blur(0);
              filter: blur(0);
      opacity: 1;
    }
    100% {
      transform: translateY(-1000px) scaleY(1) scaleX(0.2);
      transform-origin: 50% 0%;
      -webkit-filter: blur(40px);
              filter: blur(40px);
      opacity: 0;
    }
  }

@-webkit-keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-webkit-keyframes fade-in-and-out {
    0% {
      opacity: .1;
    }
    50% {
      opacity: .44;
    }
    100% {
      opacity: .1;
    }
  }
  @keyframes fade-in-and-out {
    0% {
      opacity: .1;
    }
    50% {
      opacity: .44;
    }
    100% {
      opacity: .1;
    }
  }


  @-webkit-keyframes fade-in-and-out2 {
    0% {
      opacity: .44;
    }
    50% {
      opacity: .1;
    }
    100% {
      opacity: .44;
    }
  }
  @keyframes fade-in-and-out2 {
    0% {
      opacity: .44;
    }
    50% {
      opacity: .1;
    }
    100% {
      opacity: .44;
    }
  }

@-webkit-keyframes slide-out-right {
    0% {
      transform: translateX(0);
      opacity: 1;
    }
    100% {
      transform: translateX(1000px);
      opacity: 0;
    }
  }
  @keyframes slide-out-right {
    0% {
      transform: translateX(0);
      opacity: 1;
    }
    100% {
      transform: translateX(1000px);
      opacity: 0;
    }
  }

@-webkit-keyframes fade-out {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  @keyframes fade-out {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  
@-webkit-keyframes slide-in-bottom {
    0% {
      transform: translateY(1000px);
      opacity: 0;
    }
    100% {
      transform: translateY(0px);
      opacity: 1;
    }
  }
  @keyframes slide-in-bottom {
    0% {
      transform: translateY(1000px);
      opacity: 0;
    }
    100% {
      transform: translateY(0px);
      opacity: 1;
    }
  }
  
  
  
  


  
.mobile-navbar-top {
    position: fixed;
    width: 100%;
    height: 50px;
    top: 0;
    padding-top: 0px;
    z-index: 999999;
    background: transparent;
    color: white; 


}

i {
    color: white;
}


a.navbar-link-artists {
    position: relative;
    top: 50%;
    transform: translate(0, -50%);
    display: inline-block;
    color: black;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 8pt;
    font-weight: 400;
    text-decoration: none;
    vertical-align: middle;
    padding-right: 2em;
    z-index: 100000000;
    float: right;
    text-transform: uppercase;
}

.navbar-link-artists i {
    color: black;
    font-size: 11.5pt;
    font-weight: lighter;
}


a.navbar-logo {
    position: absolute;
    height: 55px;
    width: 58%;
    top: 0px;
    left: 50%;
    transform: translate(-50%);
    font-size: 13pt;
    font-weight: 300;
}


img.nav-logo {
    position: relative;
    top: 50%;
    left: 50%;
    max-width: 90%;
    max-height: 62px;
    transform: translate(-50%, -50%);
}

a {
    text-decoration: none;
}

.fold-menu-hidden {
    position: absolute;
    z-index: 2000000000000;
    width: 100%;
    height: 100vh;
    background: linear-gradient(to right, #7b4397f5, #e72f60f5);
    top: -110vh;
    left: 0; right: 0;
    transition: .4s;
}

.fold-menu {
    position: fixed;
    z-index: 2000000000000;
    height: 100vh;
    width: 100%;
    background-color: #161617f1;
    background: linear-gradient(to right, #7b4397f5, #e72f60f5);
    bottom: 0;
    left: 0; right: 0;
    top: 0;
    transition: .4s;
    text-align: center;
}

.menu-button {
    position: absolute;
    background: transparent;
    vertical-align: top;
    text-align: left;
    z-index: 999999999;
    width: 50px;
    height: 50px;
    margin-left: 7px;
    margin-top: 0;
    padding: 15px;
}   

.menu-button i {
    top: 0;
    font-size: 16.5pt;
}

.menu-overlay {
    z-index: 9999;
    background-color: rgba(116, 116, 116, 0.658);
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 101vh;
}

.fold-title {
        color: white;
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
        font-size: 10.5pt;
        font-weight: 400;
        padding: 0;
        padding-top: 15px;
        padding-left: 5px;
        padding-bottom: 10px;
        background-color: transparent;
        text-align: center;
}

.fold-title i {
    position: absolute;
    right: 20px;
    display: inline-block;
    font-size: 14pt;
    opacity: .8;
}

.blur {

    background: linear-gradient(rgba(0, 0, 0, 0.773), rgba(0, 0, 0, 0.249));
    -webkit-backdrop-filter: blur(8px);
            backdrop-filter: blur(8px);
 
 
}

.cart-num {
    position: fixed;
    top: 8px;
    right:  14px;
    background-color: transparent;
    color: white;
    padding: 13px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 50%;
    font-size: 14px;
    z-index: 9999999;
}

.cart-num::after {
    z-index: -100000;
    border-radius: 50px;
    content: '';
    display: block;    
    position: absolute;
    top: -1px;
    left: -1px;
    width: calc(100% + 2.5px);
    height: calc(100% + 2px);
    transform: translateZ(-1px);
    background: linear-gradient(306deg, black, #7b4397, black, #e72f60);
    background-size: 400% 400%;
 
    -webkit-animation: WelcomePageGradient 4s ease infinite;
    animation: WelcomePageGradient 4s ease infinite;
 }

/* Artist Fold */

.artist {
    display: inline-block;
    width: 50%;
    text-align: center;
    margin: 6px auto;
}

.artist:hover {
    opacity: .8;
}

.artist-link {
position: relative;
margin: 0 auto;
background-color: transparent;
}

.artist-link:hover {
   opacity: .8;
   cursor: pointer;
}

.artist-image {
   position: relative;
   width: 95%;
   background-color: transparent;
   transition: .2s;
   height: auto;
   overflow: hidden;
}

.artist-image-2 {
    width: 100%;
    position: relative;
    opacity: 1;
    transition: opacity .25s ease-in-out;
    -moz-transition: opacity .25s ease-in-out;
    -webkit-transition: opacity .25s ease-in-out;
 }

 .artist-position {
    font-size: 10pt;
    font-weight: 300;
    text-transform: uppercase;
    right: 0;
    padding: 0;
    padding-bottom: 0;
    padding-top: 0;
    position: relative;
    width: 100%;
    text-align: right;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: 300;
    background: -webkit-linear-gradient(0deg, rgb(192, 192, 192), white);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
 }

 a.instagram {
    display: none;
    background-color: transparent;
    font-size: 1em;
    font-weight: lighter;
    margin-top: -25px;
    padding-top: 0;
    padding: .01em;
    background-color: transparent;
 }


 .white {
     background-color: white;
     height: 54px;
 }

 .right-link {
     position: absolute;
     right: 20px;
     top: 10px;
     color: white;
 }

 .up-front {
     z-index: 9999;
     position: fixed;
 }

 .float-left:hover {
    cursor: pointer;
 }

 .cart-num:hover {
    cursor: pointer;
 }

 .category-label-menu {
    color: black;
    font-size: 28px;
 
    font-family: "Sanz-HeavyCond", sans-serif;
    line-height: 1.2em;
    margin-top: 18px;
    margin-left: 10px;
 
  
 }

 .gradientized {
    font-synthesis: none;
    -moz-font-feature-settings: "kern";
    -webkit-font-smoothing: antialiased;
    color: transparent;
    background: linear-gradient(to right, #eb4191, #7c4a8a);
    background-clip: text;
    -webkit-background-clip: text;
 }

 .gradientized i {
    font-synthesis: none;
    -moz-font-feature-settings: "kern";
    -webkit-font-smoothing: antialiased;
    color: transparent;
    background: linear-gradient(to right, #eb4191, #7c4a8a);
    background-clip: text;
    -webkit-background-clip: text;
 }

 .shop-category-links {
    display: block;
    width: 100vw;
    margin-top: 55px;
    white-space: nowrap;
    overflow: hidden;
    text-align: center;
}

.menu-body {
    margin: 40px;
    margin-top: 70px;
}


.category-link {
    display: inline-block;
    position: relative;
    margin: 0 auto; 
}

.block {
    display: block;
}

.subcategory-links {
    display: block;
    width: 100vw;
    overflow: hidden;
    text-align: center;
}

.brand-link {
    font-weight: 300;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    text-transform: uppercase;
    font-size: 7.5pt;
    padding: 9px;
    padding-left: 12px;
    padding-right: 12px;
    border-radius: 20px;
}

.pos-absolute {
    position: fixed;
    z-index: 999999999999;
    background: white;
    top: 0; height: 100vh;
    width: 100%;

}

.bubble {
    margin: 7px;
    color: white;
    font-weight: 300;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    text-transform: uppercase;
    font-size: 7.5pt;
    padding: 9px;
    padding-left: 12px;
    padding-right: 12px;
    border-radius: 20px;
    background-color: rgba(255, 255, 255, 0.158);
}

.hidden {
    display: none;
}

.hamburger-one {
    position: fixed;
    height: 1px;
    color: white;
    background: white;
    width: 18px;
    top: 18px;
    z-index: 99999;
    transition: .6s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.rotate-one {
    position: absolute;
    height: 1px;
    color: black;
    background: black;
    width: 18px;
  
    z-index: 999999999999999999999;
    rotate: 45deg;
    transition: .6s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.hamburger-two {
    position: fixed;
    height: 1px;
    color: white;
    background: white;
    width: 18px;
    top: 26px;
    z-index: 999999;
    transition: .6s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.rotate-two {
    position: absolute;
    display: block;
    height: 1px;
    color: black;;
    background: black;
    width: 18px;
  
    z-index: 9999999999999999999999999999999999999999;
    rotate: -45deg;
    transition: .6s cubic-bezier(0.075, 0.82, 0.165, 1);
}

@media (max-width: 800px) {
    .mobile-navbar-top {
        position: fixed;
        width: 100%;
        height: 50px;
        top: 0;
        padding-top: 0px;
        z-index: 999999;
        background: transparent;
        color: white; 
    
    
    }
    
    i {
        color: white;
    }
    
    
    a.navbar-link-artists {
        position: relative;
        top: 50%;
        transform: translate(0, -50%);
        display: inline-block;
        color: black;
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
        font-size: 8pt;
        font-weight: 400;
        text-decoration: none;
        vertical-align: middle;
        padding-right: 2em;
        z-index: 100000000;
        float: right;
        text-transform: uppercase;
    }
    
    .navbar-link-artists i {
        color: black;
        font-size: 11.5pt;
        font-weight: lighter;
    }
    
    
    a.navbar-logo {
        position: absolute;
        height: 55px;
        width: 58%;
        top: 0px;
        left: 50%;
        transform: translate(-50%);
        font-size: 13pt;
        font-weight: 300;
    }
    
    
    img.nav-logo {
        position: relative;
        top: 50%;
        left: 50%;
        max-width: 90%;
        max-height: unset;
        transform: translate(-50%, -50%);
    }
    
    a {
        text-decoration: none;
    }
    
    .fold-menu-hidden {
        position: absolute;
        z-index: 2000000000000;
        width: 100%;
        height: 100vh;
        background: linear-gradient(to right, #7b4397f5, #e72f60f5);
        top: -110vh;
        left: 0; right: 0;
        transition: .4s;
    }
    
    .fold-menu {
        position: fixed;
        z-index: 2000000000000;
        height: 100vh;
        width: 100%;
        background-color: #161617f1;
        background: linear-gradient(to right, #7b4397f5, #e72f60f5);
        bottom: 0;
        left: 0; right: 0;
        top: 0;
        transition: .4s;
        text-align: center;
    }
    
    .menu-button {
        position: absolute;
        background: transparent;
        vertical-align: top;
        text-align: left;
        z-index: 999999999;
        width: 70px;
        height: 70px;
        margin-left: 0px;
        margin-top: 0;
        top: 0;
        padding: 15px;
    }   
    
    .menu-button:hover {
        cursor: pointer;
    }
    .menu-button i {
        top: 0;
        font-size: 16.5pt;
    }
    
    .menu-overlay {
        z-index: 9999;
        background-color: rgba(116, 116, 116, 0.658);
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 101vh;
    }
    
    .fold-title {
            color: white;
            font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
            font-size: 10.5pt;
            font-weight: 400;
            padding: 0;
            padding-top: 15px;
            padding-left: 5px;
            padding-bottom: 10px;
            background-color: transparent;
            text-align: center;
    }
    
    .fold-title i {
        position: absolute;
        right: 20px;
        display: inline-block;
        font-size: 14pt;
        opacity: .8;
    }
    
    .blur {
    
        background: linear-gradient(rgba(0, 0, 0, 0.773), rgba(0, 0, 0, 0.249));
        -webkit-backdrop-filter: blur(8px);
                backdrop-filter: blur(8px);
     
     
    }
    
    .cart-num {
        position: fixed;
        top: 8px;
        right:  14px;
        background-color: transparent;
        color: white;
        padding: 13px;
        padding-top: 10px;
        padding-bottom: 10px;
        border-radius: 50%;
        font-size: 9.5pt;
        z-index: 9999999;
    }
    
    .cart-num::after {
        z-index: -100000;
        border-radius: 50px;
        content: '';
        display: block;    
        position: absolute;
        top: -1px;
        left: -1px;
        width: calc(100% + 2.5px);
        height: calc(100% + 2px);
        transform: translateZ(-1px);
        background: linear-gradient(306deg, black, #7b4397, black, #e72f60);
        background-size: 400% 400%;
     
        -webkit-animation: WelcomePageGradient 4s ease infinite;
        animation: WelcomePageGradient 4s ease infinite;
     }
    
    /* Artist Fold */
    
    .artist {
        display: inline-block;
        width: 50%;
        text-align: center;
        margin: 6px auto;
    }
    
    .artist:hover {
        opacity: .8;
    }
    
    .artist-link {
    position: relative;
    margin: 0 auto;
    background-color: transparent;
    }
    
    .artist-link:hover {
       opacity: .8;
       cursor: pointer;
    }
    
    .artist-image {
       position: relative;
       width: 95%;
       background-color: transparent;
       transition: .2s;
       height: auto;
       overflow: hidden;
    }
    
    .artist-image-2 {
        width: 100%;
        position: relative;
        opacity: 1;
        transition: opacity .25s ease-in-out;
        -moz-transition: opacity .25s ease-in-out;
        -webkit-transition: opacity .25s ease-in-out;
     }
    
     .artist-position {
        font-size: 10pt;
        font-weight: 300;
        text-transform: uppercase;
        right: 0;
        padding: 0;
        padding-bottom: 0;
        padding-top: 0;
        position: relative;
        width: 100%;
        text-align: right;
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
        font-weight: 300;
        background: -webkit-linear-gradient(0deg, rgb(192, 192, 192), white);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
     }
    
     a.instagram {
        display: none;
        background-color: transparent;
        font-size: 1em;
        font-weight: lighter;
        margin-top: -25px;
        padding-top: 0;
        padding: .01em;
        background-color: transparent;
     }
    
    
     .white {
         background-color: white;
         height: 54px;
     }
    
     .right-link {
         position: absolute;
         right: 20px;
         top: 10px;
         color: white;
     }
    
     .up-front {
         z-index: 9999;
         position: fixed;
     }
    
    
     .category-label-menu {
        color: black;
        font-size: 28px;
     
        font-family: "Sanz-HeavyCond", sans-serif;
        line-height: 1.2em;
        margin-top: 18px;
        margin-left: 10px;
     
      
     }
    
     .gradientized {
        font-synthesis: none;
        -moz-font-feature-settings: "kern";
        -webkit-font-smoothing: antialiased;
        color: transparent;
        background: linear-gradient(to right, #eb4191, #7c4a8a);
        background-clip: text;
        -webkit-background-clip: text;
     }
    
     .gradientized i {
        font-synthesis: none;
        -moz-font-feature-settings: "kern";
        -webkit-font-smoothing: antialiased;
        color: transparent;
        background: linear-gradient(to right, #eb4191, #7c4a8a);
        background-clip: text;
        -webkit-background-clip: text;
     }
    
     .shop-category-links {
        display: block;
        width: 100vw;
        margin-top: 55px;
        white-space: nowrap;
        overflow: hidden;
        text-align: center;
    }
    
    .menu-body {
        margin: 40px;
        margin-top: 70px;
    }
    
    
    .category-link {
        display: inline-block;
        position: relative;
        margin: 0 auto; 
    }
    
    .block {
        display: block;
    }
    
    .subcategory-links {
        display: block;
        width: 100vw;
        overflow: hidden;
        text-align: center;
    }
    
    .brand-link {
        font-weight: 300;
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
        text-transform: uppercase;
        font-size: 7.5pt;
        padding: 9px;
        padding-left: 12px;
        padding-right: 12px;
        border-radius: 20px;
    }
    
    .pos-absolute {
        position: fixed;
        z-index: 999999999999;
        background: white;
        top: 0; height: 100vh;
        width: 100%;
    
    }
    
    .bubble {
        margin: 7px;
        color: white;
        font-weight: 300;
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
        text-transform: uppercase;
        font-size: 7.5pt;
        padding: 9px;
        padding-left: 12px;
        padding-right: 12px;
        border-radius: 20px;
        background-color: rgba(255, 255, 255, 0.158);
    }
    
    .hidden {
        display: none;
    }
    
    .hamburger-one {
        position: fixed;
        height: 1px;
        color: white;
        background: white;
        width: 18px;
        top: 18px;
        z-index: 99999;
        transition: .6s cubic-bezier(0.075, 0.82, 0.165, 1);
    }
    
    .rotate-one {
        position: absolute;
        height: 1px;
        color: black;
        background: black;
        width: 18px;
      
        z-index: 999999999999999999999;
        rotate: 45deg;
        transition: .6s cubic-bezier(0.075, 0.82, 0.165, 1);
    }
    
    .hamburger-two {
        position: fixed;
        height: 1px;
        color: white;
        background: white;
        width: 18px;
        top: 26px;
        z-index: 999999;
        transition: .6s cubic-bezier(0.075, 0.82, 0.165, 1);
    }
    
    .rotate-two {
        position: absolute;
        display: block;
        height: 1px;
        color: black;;
        background: black;
        width: 18px;
      
        z-index: 9999999999999999999999999999999999999999;
        rotate: -45deg;
        transition: .6s cubic-bezier(0.075, 0.82, 0.165, 1);
    }
}
* {
    box-sizing: border-box;
  }
  
  body {
    font-family: Arial, sans-serif;
  }
  
  #payment-form {
    max-width: 550px;
    min-width: 300px;
    margin: 150px auto;
  }
  
  .buyer-inputs {
    display: flex;
    grid-gap: 20px;
    gap: 20px;
    justify-content: space-between;
    border: none;
    margin: 0;
    padding: 0;
  }
  
  #card-container {
    margin-top: 45px;
    /* this height depends on the size of the container element */
    /* We transition from a single row to double row at 485px */
    /* Settting this min-height minimizes the impact of the card form loading */
    min-height: 90px;
  }
  
  #gift-card-container {
    margin-top: 45px;
    min-height: 90px;
  }
  
  @media screen and (max-width: 500px) {
    #card-container {
      min-height: 140px;
    }
  }
  
  #ach-button {
    margin-top: 20px;
  }
  
  #landing-page-layout {
    width: 80%;
    margin: 150px auto;
    max-width: 1000px;
  }
  
  #its-working {
    color: #737373;
  }
  
  #example-container {
    width: 100%;
    border: 1px solid #b3b3b3;
    padding: 48px;
    margin: 32px 0;
    border-radius: 12px;
  }
  
  #example-list {
    display: flex;
    flex-direction: column;
    grid-gap: 15px;
    gap: 15px;
  }
  
  #customer-input {
    margin-bottom: 40px;
  }
  
  #card-input {
    margin-top: 0;
    margin-bottom: 40px;
  }
  
  h3 {
    margin: 0;
  }
  
  p {
    line-height: 24px;
  }
  
  label {
    font-size: 12px;
    width: 100%;
  }
  
  input {
    padding: 12px;
    width: 100%;
    border-radius: 5px;
    border-width: 1px;
    margin-top: 20px;
    font-size: 16px;
    border: 1px solid rgba(0, 0, 0, 0.15);
  }
  
  input:focus {
    border: 1px solid #006aff;
  }
  
  button {
    color: #ffffff;
    background-color: #006aff;
    border-radius: 5px;
    cursor: pointer;
    border-style: none;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    outline: none;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    padding: 12px;
    width: 100%;
    box-shadow: 1px;
  }
  
  button:active {
    background-color: rgb(0, 85, 204);
  }
  
  button:disabled {
    background-color: rgba(0, 0, 0, 0.05);
    color: rgba(0, 0, 0, 0.3);
  }
  
  #payment-status-container {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid rgba(0, 0, 0, 0.05);
    box-sizing: border-box;
    border-radius: 50px;
    margin: 0 auto;
    width: 225px;
    height: 48px;
    visibility: hidden;
  }
  
  #payment-status-container.missing-credentials {
    width: 350px;
  }
  
  #payment-status-container.is-success:before {
    content: '';
    background-color: #00b23b;
    width: 16px;
    height: 16px;
    margin-right: 16px;
    -webkit-mask: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM11.7071 6.70711C12.0968 6.31744 12.0978 5.68597 11.7093 5.29509C11.3208 4.90422 10.6894 4.90128 10.2973 5.28852L11 6C10.2973 5.28852 10.2973 5.28853 10.2973 5.28856L10.2971 5.28866L10.2967 5.28908L10.2951 5.29071L10.2886 5.29714L10.2632 5.32224L10.166 5.41826L9.81199 5.76861C9.51475 6.06294 9.10795 6.46627 8.66977 6.90213C8.11075 7.4582 7.49643 8.07141 6.99329 8.57908L5.70711 7.29289C5.31658 6.90237 4.68342 6.90237 4.29289 7.29289C3.90237 7.68342 3.90237 8.31658 4.29289 8.70711L6.29289 10.7071C6.68342 11.0976 7.31658 11.0976 7.70711 10.7071L11.7071 6.70711Z' fill='black' fill-opacity='0.9'/%3E%3C/svg%3E");
    mask: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM11.7071 6.70711C12.0968 6.31744 12.0978 5.68597 11.7093 5.29509C11.3208 4.90422 10.6894 4.90128 10.2973 5.28852L11 6C10.2973 5.28852 10.2973 5.28853 10.2973 5.28856L10.2971 5.28866L10.2967 5.28908L10.2951 5.29071L10.2886 5.29714L10.2632 5.32224L10.166 5.41826L9.81199 5.76861C9.51475 6.06294 9.10795 6.46627 8.66977 6.90213C8.11075 7.4582 7.49643 8.07141 6.99329 8.57908L5.70711 7.29289C5.31658 6.90237 4.68342 6.90237 4.29289 7.29289C3.90237 7.68342 3.90237 8.31658 4.29289 8.70711L6.29289 10.7071C6.68342 11.0976 7.31658 11.0976 7.70711 10.7071L11.7071 6.70711Z' fill='black' fill-opacity='0.9'/%3E%3C/svg%3E");
  }
  
  #payment-status-container.is-success:after {
    content: 'Payment successful';
    font-size: 14px;
    line-height: 16px;
  }
  
  #payment-status-container.is-failure:before {
    content: '';
    background-color: #cc0023;
    width: 16px;
    height: 16px;
    margin-right: 16px;
    -webkit-mask: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM5.70711 4.29289C5.31658 3.90237 4.68342 3.90237 4.29289 4.29289C3.90237 4.68342 3.90237 5.31658 4.29289 5.70711L6.58579 8L4.29289 10.2929C3.90237 10.6834 3.90237 11.3166 4.29289 11.7071C4.68342 12.0976 5.31658 12.0976 5.70711 11.7071L8 9.41421L10.2929 11.7071C10.6834 12.0976 11.3166 12.0976 11.7071 11.7071C12.0976 11.3166 12.0976 10.6834 11.7071 10.2929L9.41421 8L11.7071 5.70711C12.0976 5.31658 12.0976 4.68342 11.7071 4.29289C11.3166 3.90237 10.6834 3.90237 10.2929 4.29289L8 6.58579L5.70711 4.29289Z' fill='black' fill-opacity='0.9'/%3E%3C/svg%3E%0A");
    mask: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM5.70711 4.29289C5.31658 3.90237 4.68342 3.90237 4.29289 4.29289C3.90237 4.68342 3.90237 5.31658 4.29289 5.70711L6.58579 8L4.29289 10.2929C3.90237 10.6834 3.90237 11.3166 4.29289 11.7071C4.68342 12.0976 5.31658 12.0976 5.70711 11.7071L8 9.41421L10.2929 11.7071C10.6834 12.0976 11.3166 12.0976 11.7071 11.7071C12.0976 11.3166 12.0976 10.6834 11.7071 10.2929L9.41421 8L11.7071 5.70711C12.0976 5.31658 12.0976 4.68342 11.7071 4.29289C11.3166 3.90237 10.6834 3.90237 10.2929 4.29289L8 6.58579L5.70711 4.29289Z' fill='black' fill-opacity='0.9'/%3E%3C/svg%3E%0A");
  }
  
  #payment-status-container.is-failure:after {
    content: 'Payment failed';
    font-size: 14px;
    line-height: 16px;
  }
  
  #payment-status-container.missing-credentials:before {
    content: '';
    background-color: #cc0023;
    width: 16px;
    height: 16px;
    margin-right: 16px;
    -webkit-mask: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM5.70711 4.29289C5.31658 3.90237 4.68342 3.90237 4.29289 4.29289C3.90237 4.68342 3.90237 5.31658 4.29289 5.70711L6.58579 8L4.29289 10.2929C3.90237 10.6834 3.90237 11.3166 4.29289 11.7071C4.68342 12.0976 5.31658 12.0976 5.70711 11.7071L8 9.41421L10.2929 11.7071C10.6834 12.0976 11.3166 12.0976 11.7071 11.7071C12.0976 11.3166 12.0976 10.6834 11.7071 10.2929L9.41421 8L11.7071 5.70711C12.0976 5.31658 12.0976 4.68342 11.7071 4.29289C11.3166 3.90237 10.6834 3.90237 10.2929 4.29289L8 6.58579L5.70711 4.29289Z' fill='black' fill-opacity='0.9'/%3E%3C/svg%3E%0A");
    mask: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM5.70711 4.29289C5.31658 3.90237 4.68342 3.90237 4.29289 4.29289C3.90237 4.68342 3.90237 5.31658 4.29289 5.70711L6.58579 8L4.29289 10.2929C3.90237 10.6834 3.90237 11.3166 4.29289 11.7071C4.68342 12.0976 5.31658 12.0976 5.70711 11.7071L8 9.41421L10.2929 11.7071C10.6834 12.0976 11.3166 12.0976 11.7071 11.7071C12.0976 11.3166 12.0976 10.6834 11.7071 10.2929L9.41421 8L11.7071 5.70711C12.0976 5.31658 12.0976 4.68342 11.7071 4.29289C11.3166 3.90237 10.6834 3.90237 10.2929 4.29289L8 6.58579L5.70711 4.29289Z' fill='black' fill-opacity='0.9'/%3E%3C/svg%3E%0A");
  }
  
  #payment-status-container.missing-credentials:after {
    content: 'applicationId and/or locationId is incorrect';
    font-size: 14px;
    line-height: 16px;
  }
  
  #payment-status-container.is-success.store-card-message:after {
    content: 'Store card successful';
  }
  
  #payment-status-container.is-failure.store-card-message:after {
    content: 'Store card failed';
  }
  
